<template>
  <button
    :class="{ mobile: isMobile, 'active-section': isActive }"
    @click="emitRedirected"
  >
    {{ $t(`navigation.${name}`) }}
  </button>
</template>

<script>
import t from "vue-types";

export default {
  name: "NavigationButton",
  props: {
    isActive: t.bool,
    name: t.string,
    isMobile: t.bool.def(false),
  },
  methods: {
    emitRedirected() {
      if (this.$route.hash === `#${this.name}`) return;
      if (this.$route.name !== "home")
        this.$router.push({ name: "home", hash: `#${this.name}` });
      else this.$router.replace({ hash: `#${this.name}` });
      this.$emit("redirected");
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../styles/navigation.scss";
button {
  font-size: $size-desktop;
  text-decoration: none;
  color: $color-black;
  border: none;
  background-color: transparent;
  font-weight: 600;
}
button:hover {
  cursor: pointer;
}
button.mobile {
  font-size: $size-mobile;
  color: white;
}
button:hover {
  color: $color-grey-hover;
}
button.active-section {
  color: $color-yellow-active;
}
</style>
