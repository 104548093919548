<template>
  <section class="corporate-vision">
    <div class="align-center">
      <div class="container">
        <div class="corporate-vision-group">
          <div
            class="corporate-vision-group__vision"
            :class="{ china: !showEnglishTranslation }"
          >
            <h3>{{ $t("corporate.vision") }}</h3>
            <div class="info">
              <p>{{ $t("corporate.visionParagraph") }}</p>
              <p>{{ $t("corporate.visionParagraph2") }}</p>
              <p>{{ $t("corporate.visionParagraph3") }}</p>
            </div>
          </div>
          <div
            class="corporate-vision-group__values"
            :class="{ china: !showEnglishTranslation }"
          >
            <h3>{{ $t("corporate.values") }}</h3>
            <p v-if="showEnglishTranslation" class="info">
              {{ $t("corporate.valuesParagraph") }}
            </p>
            <div v-else class="info china">
              <p>{{ $t("corporate.valuesParagraph") }}</p>
              <p>{{ $t("corporate.valuesParagraph2") }}</p>
            </div>
          </div>
          <div
            class="corporate-vision-group__culture"
            :class="{ china: !showEnglishTranslation }"
          >
            <h3>{{ $t("corporate.culture") }}</h3>
            <p class="info">{{ $t("corporate.cultureParagraph") }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "CorporateVision",
  computed: {
    showEnglishTranslation() {
      return this.$i18n.locale === "en";
    },
  },
};
</script>

<style scoped lang="scss">
.corporate-vision {
  background-color: #f7f7f7;
  &-group {
    margin: 60px 0;
    font-size: 18px;
    font-weight: 500;
    > div {
      margin-bottom: 60px;
    }
    h3 {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 30px;
    }
    p {
      margin-bottom: 30px;
    }
    .info.china p:first-child {
      margin-bottom: 0;
    }

    @media (min-width: 1001px) {
      margin: 100px 0 0 0;
      font-size: 22px;
      > div {
        display: grid;
        grid-template-columns: 240px 1fr;
        margin-bottom: 50px;
        .info p:not(:last-child) {
          margin-bottom: 25px;
        }
        p {
          margin-bottom: 0;
        }
        &:last-child {
          margin-bottom: 100px;
        }
      }
      > div.china {
        grid-template-columns: 170px 1fr;
        &:last-child {
          margin-bottom: 100px;
        }
        .info p,
        p {
          margin-bottom: 0;
        }
      }
      h3 {
        font-size: 22px;
        margin-bottom: 0;
      }
    }
  }
}
</style>
