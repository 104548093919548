import { render, staticRenderFns } from "./NavigationButton.vue?vue&type=template&id=81ddaf22&scoped=true&"
import script from "./NavigationButton.vue?vue&type=script&lang=js&"
export * from "./NavigationButton.vue?vue&type=script&lang=js&"
import style0 from "./NavigationButton.vue?vue&type=style&index=0&id=81ddaf22&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81ddaf22",
  null
  
)

export default component.exports