<template>
  <section class="publications">
    <div class="align-center">
      <div class="publications-container container">
        <header class="publications-header">
          <h1>LATEST PUBLICATION:</h1>
          <h1><i>GeCell Therapeutics 2023</i></h1>
        </header>
        <div class="publications-content-container">
          <article class="publications-content">
            <p class="text-justify-en">
              Accurate Integration of PiggyBac Transposon into Defined Genome
              Locus by using Engineered PBase fused to Cas9
            </p>
            <p>
              <i>
                Xu Naiqing, Hu Xiaohan, Fang Yuan, Liu Jingxian, Sun Kailiang,
                Xu Tian.
              </i>
            </p>
          </article>
          <article class="publications-content">
            <p class="text-justify-en">
              Treatment of Recessive Dystrophic Epidermolysis Bullosa by
              Transplantation of <i>COL7A1</i> Transgenic Tissue-engineered Skin
              Grafts
            </p>
            <p>
              <i>
                Xiaogang Deng, Weili Cong, Zhiming Chen, Zheng Zhang, Xian Liu,
                Wenjing Bai, Yijun Li, Xi Wang, Yingcun Wang, Qianqian Chen,
                Liwen Zhang, Xihui Gu, Yi Zhang, Kailiang Sun, Xiaoyang Wu, Yong
                Yang, Tian Xu.
              </i>
            </p>
            <button
              class="publications-content-more"
              :class="{ china: isChinaLanguage }"
              @click="navigateToPublications"
            >
              {{ $t("home.morePublications") }}
            </button>
          </article>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Publications",
  computed: {
    isChinaLanguage() {
      return this.$i18n.locale !== "en";
    },
  },
  methods: {
    navigateToPublications() {
      this.$router.push({ name: "publications", params: { noScroll: true } });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../styles/navigation.scss";
.publications {
  background-color: #fff;
  &-container {
    margin: 60px 30px;
  }
  &-header {
    margin-bottom: 30px;
    h1 {
      font-size: 26px;
    }
  }
  &-content {
    flex: 1;
    p {
      margin-bottom: 30px;
      font-size: 18px;
    }
    &-more {
      text-decoration: none;
      color: #000;
      background-color: #fff;
      border: 1px solid #fbbe02;
      border-radius: 15px;
      font-size: 14px;
      padding: 6px 13px;
      font-weight: 600;
    }
    &-more:hover {
      cursor: pointer;
      background-color: #f7f7f7;
    }
    &-more.china {
      border-radius: 20px;
      padding: 5px 18px 7px 18px;
    }
  }
  &-content:last-child {
    margin-top: 60px;
  }

  @media (min-width: 1001px) {
    &-content-container {
      display: flex;
    }
    &-container {
      margin: 100px 30px;
    }
    &-header {
      margin-bottom: 50px;
      h1 {
        font-size: 30px;
      }
    }
    &-content {
      padding-right: 100px;
      p {
        margin-bottom: 50px;
        font-size: 21px;
      }
      &-more {
        border-radius: 22px;
        font-size: 18px;
        padding: 10px 18px;
      }
    }
    &-content:last-child {
      margin-top: 0;
      padding: 0 0 0 100px;
    }
  }
}
</style>
