import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import News from "../views/News.vue";
import Publications from "../views/Publications.vue";
import { homeHeightService } from "../utils/observables";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/news",
    name: "news",
    component: News,
  },
  {
    path: "/publications",
    name: "publications",
    component: Publications,
  },
  {
    path: "*",
    redirect: "/",
  },
];

function pageOffset() {
  const isMobile = JSON.parse(localStorage.getItem("isMobile"));
  return isMobile ? 74 : 94;
}

function syncScrollBehavior(to, savedPosition) {
  if (to.hash) {
    return {
      selector: to.hash,
      behavior: "smooth",
      offset: {
        y: pageOffset(),
      },
    };
  }
  if (savedPosition) return savedPosition;
  return { left: 0, top: 0 };
}

function asyncScrollBehaviour(to) {
  return new Promise((resolve) => {
    const subscription = homeHeightService.listen().subscribe(() => {
      resolve({
        selector: to.hash,
        behavior: "smooth",
        offset: {
          y: pageOffset(),
        },
      });
      subscription.unsubscribe();
    });
  });
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: async (to, from, savedPosition) => {
    if (to.params?.noScroll) return;
    if (to.name !== from.name || to.fullPath === from.fullPath)
      return await asyncScrollBehaviour(to);
    else return syncScrollBehavior(to, savedPosition);
  },
  routes,
});

export default router;
