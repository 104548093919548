export default {
  navigation: {
    about: "关于我们",
    platform: "技术平台",
    news: "新闻",
    team: "团队",
    pipeline: "研发管线",
    cmc: "CMC",
    publications: "研究论文",
    career: "职业机会",
    contact: "联系我们",
  },
  home: {
    aboutUs: "关于我们",
    aboutUsParagraph:
      "利用非病毒的基因工程技术和基因递送技术，精缮科技致力于为遗传疾病和肿瘤开发创新性的细胞和基因治疗方法。",
    ourPlatform: "技术平台",
    ourPlatformHeading: "基于PiggyBac转座子的基因工程技术",
    ourPlatformParagraph:
      "PiggyBac转座子系统是一种安全高效的转基因技术方法，可以实现对多种细胞类型进行基因工程改造；作为一种高容量(200kb)的转基因载体，利用PiggyBac系统可以在一次转基因改造中将多个功能或调控元件插入目标细胞的基因组。同时，我们也在对PiggyBac转座酶进行进一步改造，以使其具备位点特异性转基因的能力。",
    genePlatform: "基于阳离子多聚物的非病毒基因递送技术",
    genePlatformParagraph:
      "递送技术是非病毒基因治疗的核心挑战之一。我们开发了一种基于阳离子聚合物的基因递送技术平台，通过对聚合物结构的改造，可以实现对不同类型的核酸药物(DNA，siRNA，RNP等)靶向不同组织或细胞的递送。",
    latestNews: "新闻",
    latestNewsArticleTitleTop: "精缮科技的GC101临床试验获国家药监局批准",
    latestNewsArticle:
      "上海精缮生物科技有限责任公司（“精缮科技”）自主研发的细胞和基因治疗产品GC101（“该产品”）的临床试验申请于近日获国家药品监督管理局批准。该产品为1类治疗用生物制品（创新型生物制品），可用于隐性营养不良型大疱性表皮松解症。",
    moreNews: "更多新闻",
    cmc: "CMC",
    cmcParagraph:
      "精缮生物目前已有自己的GMP车间支持不同来源的NK细胞的工艺开发和生产。公司的最终目标是开发“现货型”的细胞治疗产品用于血液瘤和实体瘤。NK细胞的开发和生产过程非常复杂，涉及到不同的技术，其中包括基因编辑，应用PiggyBac转座子进行基因递送，体外细胞分化和成熟。我们计划在2023年底，建成新的研发和生产基地，位于上海张江，面积约4000平米。建成后将有效支持我们的NK项目的管线开发，扩大细胞的GMP生产能力。",
    morePublications: "更多研究论文",
  },
  news: {
    GCK01Offer: {
      title: "GCK01项目临床招募广告",
      info1:
        "华中科技大学同济医学院附属协和医院 血液科 正在开展一项GCK-01细胞注射液联合利妥昔单抗治疗复发或难治性 B细胞非霍奇金淋巴瘤安全性和有效性的早期临床研究。",
      info2:
        "该研究将主要评估GCK-01 NK细胞注射液联合利妥昔单抗治疗复发或难治性B细胞非霍奇金淋巴瘤患者的安全性和 耐受性。",
      info3:
        "该研究已获得本院临床试验伦理委员会的批准，准备在本院开展临床研究，计划招募9-12例符合研究要求的受试者。",
      titleList: "若您符合以下条件，即可与我们联系：",
      info1List: "年龄18-78岁（含18岁和75岁），性别不限",
      info2List: "患有复发或难治性CD20阳性B细胞非霍奇金淋巴瘤；",
      info3List: "身体状况较好，能坚持按期来医院复查随访；",
      info4List: "实验室主要检查指标基本正常；",
      info5List: "自愿签署书面知情同意书，并按要求配合完成相关评估。",
      info4:
        "如果您想加入该临床研究，请联系研究医生进行沟通，医生将会给您进行详细的讲解和评估。",
      info5:
        "若经筛选后符合条件并进入该研究，申办单位将为您免费提供研究相关药物及相关检查，您不会因为参加本研究而 产生额外费用。",
      info6: "联系信息如下：",
      info7: "联系医院：华中科技大学同济医学院附属协和医院血液科",
      info8Lines: {
        line1: "联系方式：",
        line2: "鲁老师 15872342685",
        line3: "廖老师 18164207911",
      },
      info9: "所在科室：内科楼一号楼血液科接待室",
      info10: "联系时间：工作日 08:00-17:00",
    },
    gck01Trial: "祝贺精缮科技GCK01项目项目临床试验正式启动！",
    donation: {
      title:
        "精缮科技CEO金夷博士受邀参加 “爱不罕见”慈善晚宴，并代表公司为本次公益活动助力。",
      info1:
        "2023年2月25日，由瑞鸥公益基金会主办的“2023瑞鸥新年慈善晚宴”在杭州西溪温情开筵。此次晚宴以“爱不罕见”为主题，诚邀100位各界爱心人士共同参与。",
      info2:
        "精缮科技CEO金夷博士受邀参加晚宴，并代表公司向本次公益基金捐献6万余元，本次捐献善款将用于瑞鸥2023年度#罕见病# 科研资助项目和基金会运营。",
    },
    award: "喜讯！热烈祝贺我司荣获2022年度上海市“虹口区创新示范单位”荣誉称号！",
    article1: {
      title: "精缮科技的GC101临床试验获国家药监局批准",
      info: "(2022年11月14日，中国上海）2022年11月14日，上海复星医药（集团）股份有限公司（“复星医药”，股票代码：600196.SH；02196.HK）宣布，控股子公司上海精缮生物科技有限责任公司（“精缮科技”）自主研发的细胞和基因治疗产品GC101（“该产品”）的临床试验申请于近日获国家药品监督管理局批准。该产品为1类治疗用生物制品（创新型生物制品），可用于隐性营养不良型大疱性表皮松解症（Recessive Dystrophic Epidermolysis Bullosa，RDEB）患者难以愈合的体表伤口的移植治疗，精缮科技拟于条件具备后于中国境内（不包括港澳台地区）开展I期临床试验。截至目前，于全球范围内尚无同类用于RDEB治疗的产（药）品获批上市。",
    },
    expo: {
      title: "精缮科技首次亮相BEYOND国际科技创新博览会",
      info1:
        "药品监管改革持续深化，我国医药产业加速迈向高质量发展转型，为高价值创新药物研发转化插上了腾飞的翅膀。当前，新一轮科技革命与产业升级方兴未艾，国内头部医药创新企业纷纷把提升自主研发和源头创新能力作为夯实长期竞争力的发展目标。",
      info2:
        "在5月10-12日举行的“第三届BEYOND国际科技创新博览会（BEYOND Expo 2023）”上，连续第三年深度参与展会的 复星医药，携手复宏汉霖、复星凯特、复星健康以及深度孵化平台复星领智-精缮科技，亮相生命科学展区，集中展现 了聚焦前沿科技创新的自主研发成果，以及全日化的医疗健康产业生态圈布局。",
      info3:
        "2022年11月，精缮科技自主研发的细胞和基因治疗产品GC101的临床试验申请获国家药品监督管理局批准。该产品为1类治疗用生物制品，可用于隐性营养不良型大疱性表皮松解症（RDEB）患者难以愈合的体表伤口的移植治疗。截至目前，在全球范围内尚无同类用于RDEB治疗的产品获批上市。",
      info4:
        "精缮科技首席执行官金夷表示，通过自主研发的基因编写技术casPB-Acting，公司实现了高达11.8Kb的大片段基因敲除和敲入。“大片段的基因编写技术不仅能够治疗和覆盖更多以前无治疗手段的基因疾病，也能大幅度提高细胞治疗中基因工程改造的效率。目前，精缮科技已搭建了完善的团队和工艺体系，产品开发能力也获得CDE认可，首款产品IND申请于去年11月获批。”",
      info5:
        "在业内看来，精缮科技在前期围绕RDEB这一罕见病领域的能力积累，有助于未来能够快速嫁接至新的疾病研究方向，具备良好的商业化前景。",
    },
    mtb: {
      title: "凝心聚力·开创未来-2023年公司团建活动完美绽放",
      info1:
        "为了调节工作压力，营造激情、责任、快乐的工作氛围，从而让大家更好地投入到接下来的工作中。",
      info2:
        "公司于2023年5月12日、13日、14日组织安排了“凝心聚力·开创未来”团建活动，旨在丰富员工业余生活之余，进一步加强团队凝聚力，提升团队间的团结协作能力。活动场面既激情如火又温馨和谐，每个活动中员工们都默契配合，都发扬了无私奉献、团结协作的精神，相互帮助、鼓励，尽情挥洒青春的激情。",
      info3:
        "俗话说，单丝不成线，独木不成林!同样的一块铁，可以锯融消损，也可以百炼成钢;同样的一支队伍，可以碌碌无为，也可以成就大业，一个团队中有各种角色，每个人都要找准自己的位置，因为没有完美的个人，只有完美的团队!",
    },
  },
  team: {
    ourTeam: "团队",
    readBio: "了解更多",
    member1: {
      name: "许田 博士",
      position: "科学创始人",
      aboutCh: "董事会成员；复旦大学学士，耶鲁大学博士；西湖大学副校长",
      about:
        "曾任耶鲁大学HHMI研究员和遗传学系副主席>20年；>10家生物技术公司的联合创始人/顾问，包括Drug Farm，Butterfly Network，AI Therapeutics，DoubleRainbow Biosciences等；在哺乳动物中率先发现了PiggyBac系统。",
    },
    member2: {
      name: "吴小阳 博士",
      position: "共同科学创始人",
      aboutCh: "南京大学理学学士，康奈尔大学博士，洛克菲勒大学博士后",
      about: "芝加哥大学副教授，研究重点是皮肤干细胞和皮肤病的生物学。",
    },
    member3: {
      name: "金夷 博士",
      position: "首席执行官",
      about:
        "在包括伯克利分校、拜耳、金斯瑞等国际知名高校研究所、大型药企和CRO公司，20多年学术科研和企业产品研发经验；重点专注于肿瘤免疫、自身免疫、单基因遗传病等专业领域，拥有丰富的早研和临床前开发经历，包括推进技术平台搭建等；在学术研究方面，分别在Cell、Molecular Cell、Development Cell、J. Cell Biol.、J. Cell. Biochem.等期刊杂志发表数十篇学术论文，丰富的团队管理能力，包括多学科、跨专业团队管理，尤其是早期研发、转化医学和临床前药学，如CMC、QA/QC等；四川大学本科、爱荷华州立大学博士、加州大学伯克利分校博士后。",
    },
    member4: {
      name: "刘敬贤 博士",
      position: "首席科学官",
      about:
        "在A*STAR、睿智化学、Cytovant Sciences等研究所和企业，拥有近20年学术、工业界工作经验，参与多个项目和产品的开发；深耕于肿瘤免疫、药物研发、抗体工程、细胞疗法等领域，包括TCR-T产品的早期发现和后续合作开发，发表十余篇学术论文，包括PNAS、Nature、J Virol.、Eur J Immunol.、Biochemistry.、Protein Sci.等期刊；丰富的早期研发团队管理能力和跨学科沟通协调经验；西安交通大学本科和硕士、新加坡国立大学博士、南洋理工大学博士后。",
    },
    member5: {
      name: "樊辉 博士",
      position: "首席技术官",
      about:
        "在香雪医疗，长期致力于创新技术的产业化过程，主要负责细胞治疗相关的工艺开发和GMP生产；成功推动了多个TCR-T细胞治疗项目进入临床试验，分别获中美批准开展临床试验；在学术研究方面，分别在PNAS, Biochemistry, J. Virol., Nucleic Acid Res发表过多篇学术论文。丰富的团队组织管理能力；华中科技大学本科、新加坡南洋理工大学博士、加州大学圣地亚哥分校博士后。",
    },
    member6: {
      name: "丛伟立 医学博士 博士",
      position: "工艺总监",
      about:
        "在包括上海生化所等，拥有10多年细胞治疗产品的研发和工艺开发经验，包括iPS细胞等；长春中医药大学本科、日本横宾市立大学博士。",
    },
    member7: {
      name: "张铮 博士",
      position: "质量总监",
      about:
        "在上海徕士，拥有近10年生物制品质量管理经验；丰富的团队管理和协调能力；上海交通大学本科，上海交通大学博士。",
    },
  },
  pipeline: {
    pipeline: "研发管线",
    project: "项目",
    indication: "适应症",
    target: "靶点",
    mechanism: "治疗类型",
    discovery: "发现",
    preclinical: "临床前",
    ind: "IND",
    phaseOneOfTwo: "临床1/2期",
    pivotal: "关键临床",
    geneticDisease: "遗传 疾病",
    immunoOncology: "肿瘤疾病",
    gc101: "GC-101",
    gcb202: "GCB-202",
    gck01: "GCK-01",
    gck02: "GCK-02",
    gck03: "GCK-03",
    gck05: "GCK-05",
    gck06: "GCK-06",
    rdeb: "大疱症RDEB",
    bcl: "淋巴瘤BCL",
    aml: "白血病AML",
    gbm: "脑胶质瘤 GBM",
    gc: "胃癌 GC",
    hcc: "肝癌HCC",
    col7a1: "COL7A1",
    cd20: "CD20",
    cd33cll1: "CD33/CLL1",
    her2: "Her2, EphA2, EGFRvIII, IL13Rα2",
    ct83: "CT83",
    mica: "MICA",
    cellTherapy: "细胞治疗",
    geneTherapy: "基因治疗",
    pbNkAb: "PB-NK + 抗体",
    carInk: "CAR-iNK",
  },
  career: {
    careers: "职业机会",
    careersParagraph:
      "我们诚挚的欢迎有出色的学习能力及自我驱动能力的优秀研究人员加入我们的团队，为遗传疾病和肿瘤开发创新性的细胞基因治疗方法，并为患者群体及其家庭带来新的希望！如果您有兴趣了解并加入我们的团队，可将CV发送至：joinus@gecelltx.com",
    readMore: "了解更多",
    responsibilities: "岗位职责",
    requirements: "任职要求",
    job1: {
      jobTitle: "科学家/高级科学家",
      jobResponsibility1: "负责肿瘤免疫细胞和基因治疗项目的规划、执行及推进；",
      jobResponsibility2:
        "建立肿瘤免疫细胞和基因治疗平台，包括载体设计、合成生物学、载体/靶点筛选和验证、方法开发等，制定及组织实施相关研发计划；",
      jobResponsibility3:
        "进行研究方案设计、数据处理分析、撰写实验总结及报告，进行内部或外部报告；",
      jobResponsibility4:
        "参与公司研发管线规划，跟踪肿瘤免疫治疗领域最新研究进展，对新适应症提出创新性的解决方案，设计开发策略，制订开发方案；",
      jobResponsibility5:
        "协调项目后期工艺、质量、临床等相关研究工作，负责相关SOP的起草、撰写等；",
      jobResponsibility6: "协调并管理与CRO的合作。",
      jobRequirement1:
        "国内外著名高校免疫相关专业博士或博士后，精通免疫学原理和相关知识内容，同时具备长期的免疫相关研究经验；",
      jobRequirement2:
        "熟练掌握分子生物学、细胞生物学相关实验技能，能够独立或指导技术人员进行相关实验；",
      jobRequirement3:
        "丰富的动物实验研究经历，能够独立或指导技术人员开展药效评价研究；",
      jobRequirement4:
        "具备迅速学习能力，对新兴领域和技术有巨大的学习热情，能够经过文献学习利用新方法和新技术开展研究；",
      jobRequirement5:
        "做事细致条理清晰，能够以结果为导向开展工作，具备团队合作能力，出色的沟通能力和领导力。",
    },
    job2: {
      jobTitle: "细胞工艺研究员",
      jobResponsibility1:
        "负责日常细胞实验的常规操作，包括细胞转染，扩增培养；基因表达鉴定（包括分子生物学检测：Western blot; IF; IP; qPCR等）；",
      jobResponsibility2:
        "整理实验数据，归档，并提交研发报告书以及工作总结报告书；",
      jobResponsibility3: "实验室及GMP车间的日常维护工作；",
      jobResponsibility4: "协助上级领导完成实验助理的岗位技能的基本培训。",
      jobRequirement1: "分子生物学、细胞生物学、生物技术等相关专业研究生学历；",
      jobRequirement2:
        "熟悉细胞分子生物学技术（细胞培养、基因克隆、载体构建等），熟悉相关数据库（NCBI等）的使用；",
      jobRequirement3:
        "有干细胞分化、成熟操作经验优先。有细胞治疗(CAR-T, TCR-T)工艺研发经验者优先。",
    },
    job3: {
      jobTitle: "免疫研究员",
      jobResponsibility1ch:
        "负责完成细胞免疫学相关的体内外实验，负责细胞培养，包括细胞分选、冻存和复苏等；",
      jobResponsibility1:
        "负责细胞功能性分析实验，阳性率检测、细胞表型检测、体外细胞毒性检测、细胞因子检测等；",
      jobResponsibility2:
        "负责分子生物学相关实验，Western-blot、RT-PCR、免疫组化、流式细胞术检测等；",
      jobResponsibility3: "负责细胞免疫学体内外药效评估实验；",
      jobResponsibility4: "负责项目实验数据整理和分析工作。",
      jobRequirement1:
        "具有分子生物学、细胞生物学、免疫学、基础医学等相关专业硕士学历；",
      jobRequirement2:
        "熟练掌握分子生物学方面的实验技能（如：Western-blot、RT-PCR、免疫组化、流式细胞术检测等）；",
      jobRequirement3: "掌握细胞治疗相关体内外药效评价技术；",
      jobRequirement4:
        "工作积极主动，态度严谨，责任心强，有良好的团队协作精神。",
    },
    job4: {
      jobTitle: "动物研发研究员",
      jobResponsibility1:
        "建立动物疾病模型、体内外药物评价模型及方法，对候选药物进行筛选和实验验证；",
      jobResponsibility2:
        "支持公司的新药研发，协助或负责DMPK实验设计，结果分析；",
      jobResponsibility3:
        "基于已有动物模型开展常规动物实验，辅助完成药理药效评价；",
      jobResponsibility4:
        "负责动物给药、观察、麻醉、取样、采血、解剖、手术等日常工作；",
      jobResponsibility5: "根据临床前药效研究方案，优化实验方法设计；",
      jobResponsibility6: "对实验数据进行整理和分析，形成研究（实验）报告。",
      jobRequirement1:
        "硕士学历，具有生物学、（基础或临床）医学、药学等学科的学位，临床药理学、药代动力学、病理学、生理学、细胞生物学、神经生物学、临床药学等生物医药相关专业；",
      jobRequirement2: "熟练操作实验动物，对大鼠小鼠的常规操作有较丰富经验；",
      jobRequirement3: "掌握细胞培养，细胞功能学实验等；",
      jobRequirement4: "熟悉动物疾病模型的建立和评价方法及细胞培养技术；",
      jobRequirement5: "具备独立设计实验、解决问题并形成科学推论的能力；",
      jobRequirement6:
        "对药物研发领域有浓厚兴趣，工作积极主动，态度严谨，责任心强，有良好的团队协作精神。",
    },
    job5: {
      jobTitle: "分子研发研究员",
      jobResponsibility1:
        "熟练掌握DNA抽提，质粒大提，PCR操作等分子生物学实验，经过培训能够独立完成质粒构建等工作；",
      jobResponsibility2: "保证实验数据记录的完整性、及时性和可追溯性；",
      jobResponsibility3:
        "对工作中出现的问题能够做出分析与判断，并且及时向上级报告；",
      jobResponsibility4: "完成上级安排的其他工作。",
      jobRequirement1:
        "生物技术、分子生物学、生物化学、细胞生物学、生物工程等相关专业，专业知识扎实，硕士学历；",
      jobRequirement2:
        "具有载体构建及分子克隆相关的生物实验室经验（包括在校期间的实验室工作经验）；",
      jobRequirement3:
        "熟悉掌握载体构建，引物设计，PCR操作，质粒提取等实验技术并了解相关实验原理，有Western blot、ELISA、细胞治疗研 究等经验者优先；",
      jobRequirement4:
        "能够对实验数据进行分析并完成实验记录和报告的整理和撰写；",
      jobRequirement5: "良好的英文读写及交流能力。",
    },
    job6: {
      jobTitle: "方法开发研究员（AD），质控研究员（QC）",
      jobResponsibility1:
        "支持细胞和基因治疗研发和工艺开发的相关方法开发和验证工作，包括分子生物学、细胞生物学、理化分析或生物安全性相关方法学等；",
      jobResponsibility2:
        "根据药典等相关法规要求，起草并完善方法学相关SOP、验证方案、验证报告等文件；",
      jobResponsibility3: "参与项目申报资料的撰写；",
      jobResponsibility4:
        "对工作中出现的问题能够做出分析与判断，并且及时向上级报告；定期汇报相关工作进展。",
      jobRequirement1:
        "分子生物学、细胞生物学等相关专业硕士学历，专业知识扎实；",
      jobRequirement2:
        "熟练掌握分子生物学（如qPCR、ELISA、western blot）、细胞生物学（细胞培养、流式分析）或理化分析相关实验方法；",
      jobRequirement3: "有分析方法开发经验者优先；",
      jobRequirement4: "良好的英文读写及交流能力；",
      jobRequirement5:
        "工作积极主动，态度严谨，责任心强，有良好的团队协作精神。",
    },
    job7: {
      jobTitle: "QA专员",
      jobResponsibility1: "参与研发实验室质量管理体系及文件控制体系的建立；",
      jobResponsibility2:
        "参与细胞及基因治疗治疗产品的质量管理体系和文件控制体系的建立，参与质量体系文件的制订、审核和实施；",
      jobResponsibility3:
        "跟踪学习国家有关细胞和基因治疗类产品的政策法规及管理制度，确保公司的研发及试验符合GxP规范；",
      jobResponsibility4: "负责监督及开展日常质量管理工作；",
      jobResponsibility5: "负责内部人员QA体系培训工作。",
      jobRequirement1: "生物学、药学相关专业本科及以上学历；",
      jobRequirement2: "熟知GMP和细胞和基因治疗产品相关的法律法规；",
      jobRequirement3: "1年以上生物药或细胞治疗产品的QA管理工作经验；",
      jobRequirement4: "强烈的工作责任心及严谨的逻辑思维能力；",
      jobRequirement5: "优秀的沟通协调和解决问题的能力；",
      jobRequirement6: "优秀的英文读写及交流能力。",
    },
    job8: {
      jobTitle: "项目管理经理",
      jobResponsibility1:
        "组织制定规划项目发展整体时间表，跟踪项目进展，参加项目会议，撰写会议摘要与行动项；",
      jobResponsibility2:
        "组织，参与新药项目立项，项目进展评审和结题的相关会议，协调解决项目进度中相关问题；",
      jobResponsibility3:
        "参与项目申报，物料采购，使用电子管理系统跟踪项目；负责新药研发项目资料整理归档，组织研发人员撰写专利；",
      jobResponsibility4: "协助年度项目预算，结算工作。",
      jobRequirement1:
        "具有2年以上药物相关研发项目管理经验；基因细胞治疗优先；",
      jobRequirement2: "熟悉国家相关医药法规，包括GLP、GMP、GCP等相关法规；",
      jobRequirement3: "熟悉肿瘤基因细胞治疗药物研发的市场信息；",
      jobRequirement4: "具有较强的组织、协调能力及良好的团队合作意识；",
      jobRequirement5: "硕士学位以上。",
    },
    job9: {
      jobTitle: "注册经理",
      jobResponsibility1:
        "负责新药和管线产品的注册计划与实施；制定新产品的注册计划，准备新产品的注册资料提交，如果需要还要进行上市后产品变更以及临床试验申请注册资料的准备与提交；批判性地分析数据包的合规性，指出与法规要求存在差异的数据并要求补充数据以满足法规要求；制定注册项目的整体性计划；准备对监管部门下发的缺陷函的回复，并确保在规定或者约定时间范围内将这些或者其他要求的数据递交给监管部门；依据相关法规和公司 SOP 的要求维护和更新标签/说明书；持续了解中国最新的相关法律法规；必要时给团队提供培训；持续了解足够的产品知识，以确保及时和专业地回应与公司产品相关的咨询；",
      jobResponsibility2:
        "与相关政府机构和企业支持团队建立关系，确保法规一致性并加快审批；很好的理解法规；为跨职能团队提供必要和及时的法规支持；积极收集信息，监测监管环境的变化；",
      jobResponsibility3:
        "团队合作精神。在日常运营期间，通过各种工具或沟通渠道加强与公司支持团队的联系；确保与公司内其他职能团队之间的有效沟通。",
      jobRequirement1: "药学或医学相关本科以上学历；",
      jobRequirement2:
        "工作经验及年限：至少 5 年制药行业工作经验；至少 4 年注册领域工作经验，有临床注册以及独立递交经验者优先。",
      jobRequirement3:
        "语言要求：英语口语和写作技能其他要求：良好的办公软件技能，包括 EXCEL, POWERPOINT 和 WORD；良好的人际沟通能力；团队合作精神。",
    },
    job10: {
      jobTitle: "医学经理",
      jobResponsibility1: "负责撰写临床方案及临床试验相关材料；",
      jobResponsibility2: "完成临床研究总结报告；",
      jobResponsibility3:
        "审核临床试验相关文件，包括但不限于：CRF、数据统计分析计划、数据管理计划，药物安全报告管理计划等，确保在临床试验的相关节点前完成；",
      jobResponsibility4:
        "在临床试验阶段，完成医学审核，及时发现研究中存在的问题并予以解决；",
      jobResponsibility5: "负责研发产品相关文献简述；",
      jobResponsibility6: "制定临床研发计划、完成临床研究设计；",
      jobResponsibility7: "完成IND或NDA中临床材料；",
      jobResponsibility8:
        "解决临床试验中医学相关的问题，确保临床研究严格按照研究方案和ICH-GCP, 国家相关法规、公司SOP进行。",
      jobRequirement1: "医学相关专业硕士以上学历；肿瘤项目背景；",
      jobRequirement2:
        "具有优秀的中英文写作能力和清晰地口头表达能力，善于沟通；",
      jobRequirement3: "独立工作能力强，同时具有良好的团队合作精神；",
      jobRequirement4:
        "适应能力强，可以承受合理的任务压力；时间观念强，能合理计划时间并在计划的时限内按时完成工作；",
      jobRequirement5:
        "熟悉《药品管理法》，《新药审批办法》，《药品注册管理办法》，ICH-GCP，临床试验全过程，和国内外临床研究发展与现状。",
    },
    job11: {
      jobTitle: "核酸纳米药物-研发助理",
      jobResponsibility1:
        "辅助核酸药物递送系统的研发验证，材料主要基于新型聚合物，包括但不限于脂质体、多肽/蛋白和多糖等纳米载体材料；",
      jobResponsibility2:
        "辅助开展核酸纳米药物的制剂准备、筛选优化、工艺研究，稳定性考察等工作；",
      jobResponsibility3:
        "进行核酸纳米药物的体外、体内递送效率评估和物化分析；",
      jobResponsibility4:
        "按照要求规范研究并做好原始记录及进行资料的整理，撰写实验研究报告。",
      jobRequirement1:
        "药物制剂、化学、高分子材料、生物技术、生物工程等相关专业背景，本科及以上学历；",
      jobRequirement2:
        "倾向条件：具备制剂理论知识和纳米药物研发经验，具有聚合物纳米制剂研究经验者优先；",
      jobRequirement3:
        "倾向条件：具有核酸递送材料研究经验，熟悉材料合成或相关物化和生物表征技术（如包封率、粒径和表面性质分析，体内体外递送效率分析等）；",
      jobRequirement4:
        "倾向条件：具有微流控或其他药剂制备和纳米组装方法工程知识和经验；",
      jobRequirement5: "具备熟练的英文文献检索和阅读能力；",
      jobRequirement6: "具备优秀的团队协作意识和敬业精神。",
    },
  },
  corporate: {
    vision: "企业愿景",
    visionParagraph: "愿景：成为革新性基因和细胞药物企业",
    visionParagraph2: "使命：以基因与细胞技术，研创生命健康",
    visionParagraph3:
      "质量方针：努力研发先进、稳定的基因与细胞药物，改善和治愈更多生命",
    values: "核心价值观",
    valuesParagraph: "以患者为方向标 以创新为驱动力",
    valuesParagraph2: "（以患者为本 以创新为中心）",
    culture: "企业文化",
    cultureParagraph: "精益求精，至缮至美",
  },
  contact: {
    contactUs: "联系我们",
    address: "地址",
    building: "上海市中山北一路1111号1号楼",
    business: "商务合作：",
    email: "邮箱：",
    phone: "电话：",
  },
};
