import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueMeta from "vue-meta";
import VueI18n from "vue-i18n";
import en from "@/i18n/en";
import ch from "@/i18n/ch";
import browserDetect from "vue-browser-detect-plugin";
import smoothscroll from "smoothscroll-polyfill";

Vue.use(VueMeta);
Vue.use(VueI18n);
Vue.use(browserDetect);
smoothscroll.polyfill();

import VueRx from "vue-rx";
import { Subject } from "rxjs";
Vue.use(VueRx, Subject);

const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  silentFallbackWarn: true,
  messages: { en: Object.freeze(en), ch: Object.freeze(ch) },
});

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
