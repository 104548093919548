<template>
  <div ref="home">
    <about-us ref="about" id="about" />
    <platform ref="platform" id="platform" />
    <news ref="news" id="news" />
    <team ref="team" id="team" />
    <pipeline ref="pipeline" id="pipeline" />
    <cmc ref="cmc" id="cmc" />
    <publications ref="publications" id="publications" />
    <career ref="career" id="career" />
    <contact ref="contact" id="contact" />
  </div>
</template>

<script>
import AboutUs from "@/components/home/AboutUs";
import Platform from "@/components/home/Platform";
import News from "@/components/home/News";
import Team from "@/components/home/Team";
import Pipeline from "@/components/home/Pipeline";
import Cmc from "@/components/home/Cmc";
import Publications from "@/components/home/Publications";
import Career from "@/components/home/Career";
import Contact from "@/components/home/Contact";
import { smoothScrollTime, pageLoadTime } from "../utils/constants";
import { homeHeightService } from "../utils/observables";

export default {
  name: "Home",
  components: {
    AboutUs,
    Platform,
    News,
    Team,
    Pipeline,
    Cmc,
    Publications,
    Career,
    Contact,
  },
  data() {
    return {
      lastScrollPosition: 0,
      isSmoothScrolling: false,
      smoothScrollTimer: null,
      homeHeightTimer: null,
    };
  },
  created() {
    if (this.$route.hash) this.isSmoothScrolling = true;
  },
  mounted() {
    window.addEventListener("scroll", this.onScrollEvent);
    window.addEventListener("smooth-scroll", this.onSmoothScroll);
    window.addEventListener("resize", this.onHomeResize);
    const resizeObserv = new ResizeObserver(() => {
      this.homeHeightTimer = setTimeout(() => {
        clearTimeout(this.homeHeightTimer);
        this.onHomeResize();
      }, pageLoadTime);
    });
    resizeObserv.observe(this.$refs.home);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScrollEvent);
    window.removeEventListener("smooth-scroll", this.onSmoothScroll);
    window.removeEventListener("resize", this.onHomeResize);
    clearTimeout(this.smoothScrollTimer);
    clearTimeout(this.homeHeightTimer);
  },
  computed: {
    homeRefs() {
      return [
        "about",
        "platform",
        "news",
        "team",
        "pipeline",
        "cmc",
        "publications",
        "career",
        "contact",
      ];
    },
    homeRefsReverse() {
      return [...this.homeRefs].reverse();
    },
  },
  methods: {
    onHomeResize() {
      if (this.$refs?.home?.clientHeight == null) return;
      homeHeightService.assign(this.$refs.home.clientHeight);
    },
    onSmoothScroll({ detail }) {
      this.isSmoothScrolling = detail.smoothScroll;
    },
    onScrollEvent() {
      if (this.isSmoothScrolling) {
        clearTimeout(this.smoothScrollTimer);
        this.smoothScrollTimer = setTimeout(() => {
          this.isSmoothScrolling = false;
          this.lastScrollPosition =
            window.pageYOffset || document.documentElement.scrollTop;
        }, smoothScrollTime);
        return;
      }
      this.handleNavigationActiveLink();
    },
    handleNavigationActiveLink() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if (this.lastScrollPosition > currentScrollPosition) {
        this.homeRefs.every((ref) => {
          const { bottom } = this.$refs[ref].$el.getBoundingClientRect();
          if (bottom < window.innerHeight / 2) return true;
          const hash = `#${ref}`;
          if (this.$route.hash === hash) return;
          this.$router.replace({ hash, params: { noScroll: true } });
          return false;
        });
      } else {
        this.homeRefsReverse.every((ref) => {
          const { top } = this.$refs[ref].$el.getBoundingClientRect();
          if (top > window.innerHeight / 2) return true;
          const hash = `#${ref}`;
          if (this.$route.hash === hash) return;
          this.$router.replace({ hash, params: { noScroll: true } });
          return false;
        });
      }
      this.lastScrollPosition = currentScrollPosition;
    },
  },
};
</script>
