<template>
  <nav class="nav">
    <transition name="slide-vertically">
      <div v-show="showNavbar || showMobileNavbar" class="navbar">
        <div class="navbar-flex">
          <img
            class="navbar-logo"
            src="@/assets/navigation/logo_white.png"
            alt="gecell logo"
            @click="goToHomeAbout"
          />

          <div class="navbar-desktop-flex">
            <ul class="navbar-desktop-links">
              <li
                v-for="navigationRoute in navigationRoutes"
                :key="navigationRoute.name"
              >
                <navigation-button
                  :name="navigationRoute.name"
                  :goTo="navigationRoute.goTo"
                  :isActive="navigationRoute.isActive"
                  @redirected="sendSmoothScrollEvent"
                />
              </li>
            </ul>
            <navigation-languages class="navbar-desktop-languages" />
          </div>

          <div class="navbar-mobile-toggle">
            <img
              v-show="!showMobileNavbar"
              src="@/assets/navigation/burger_icon.svg"
              alt=""
              @click="onMobileNavigation"
            />
            <img
              v-show="showMobileNavbar"
              src="@/assets/navigation/x_icon.svg"
              alt=""
              @click="onMobileNavigation"
            />
          </div>
        </div>
      </div>
    </transition>

    <transition name="slide-horizontally">
      <div v-show="showMobileNavbar" class="navbar-mobile">
        <ul class="navbar-mobile-links">
          <li
            v-for="navigationRoute in navigationRoutes"
            :key="navigationRoute.name"
          >
            <navigation-button
              :name="navigationRoute.name"
              :goTo="navigationRoute.goTo"
              :isActive="navigationRoute.isActive"
              :isMobile="true"
              @redirected="onMobileNavigation"
            />
          </li>
        </ul>
        <navigation-languages
          :isMobile="true"
          class="navbar-mobile-languages"
        />
      </div>
    </transition>
  </nav>
</template>

<script>
import NavigationButton from "./NavigationButton.vue";
import NavigationLanguages from "./NavigationLanguages.vue";
import { smoothScrollTime } from "../../utils/constants";

export default {
  name: "Navigation",
  components: {
    NavigationButton,
    NavigationLanguages,
  },
  data() {
    return {
      showNavbar: true,
      showMobileNavbar: false,
      lastScrollPosition: 0,
      isSmoothScrolling: false,
      smoothScrollTimer: null,
    };
  },
  created() {
    if (this.$route.hash) this.isSmoothScrolling = true;
  },
  mounted() {
    window.addEventListener("scroll", this.onScrollEvent);
    window.addEventListener("smooth-scroll", this.onSmoothScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScrollEvent);
    window.removeEventListener("smooth-scroll", this.onSmoothScroll);
    clearTimeout(this.smoothScrollTimer);
  },
  watch: {
    showNavbar: {
      handler(value) {
        localStorage.setItem("showNavbar", value);
      },
      immediate: true,
    },
  },
  computed: {
    activeLink() {
      return this.$route.hash;
    },
    activeRouteName() {
      return this.$route.name;
    },
    pageOffset() {
      const isMobile = JSON.parse(localStorage.getItem("isMobile"));
      return isMobile ? 75 : 95;
    },
    navigationRoutes() {
      const pages = [
        "about",
        "platform",
        "news",
        "team",
        "pipeline",
        "cmc",
        "publications",
        "career",
        "contact",
      ];
      return pages.map((page) => ({
        name: page,
        isActive:
          this.activeLink === `#${page}` ||
          this.activeRouteName === page ||
          (!this.activeLink &&
            this.activeRouteName === "home" &&
            page === "about"),
      }));
    },
  },
  methods: {
    onSmoothScroll({ detail }) {
      this.isSmoothScrolling = detail.smoothScroll;
    },
    onScrollEvent() {
      if (this.isSmoothScrolling) {
        clearTimeout(this.smoothScrollTimer);
        this.smoothScrollTimer = setTimeout(() => {
          this.isSmoothScrolling = false;
          this.lastScrollPosition =
            window.pageYOffset || document.documentElement.scrollTop;
        }, smoothScrollTime);
        return;
      }
      this.handleNavigationVisability();
    },
    handleNavigationVisability() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) return;
      if (currentScrollPosition < this.lastScrollPosition) {
        this.lastScrollPosition = currentScrollPosition;
        this.showNavbar = true;
        return;
      }
      if (currentScrollPosition > this.lastScrollPosition + this.pageOffset) {
        this.lastScrollPosition = currentScrollPosition;
        this.showNavbar = false;
        return;
      }
    },
    sendSmoothScrollEvent() {
      this.isSmoothScrolling = true;
      window.dispatchEvent(
        new CustomEvent("smooth-scroll", {
          detail: { smoothScroll: true },
        })
      );
    },
    onMobileNavigation() {
      this.sendSmoothScrollEvent();
      this.showMobileNavbar = !this.showMobileNavbar;
      if (this.showMobileNavbar) {
        document.querySelector("body").classList.add("modal-open");
      } else {
        document.querySelector("body").classList.remove("modal-open");
      }
    },
    goToHomeAbout() {
      if (this.activeLink === "#about") return;
      this.$router.replace({ path: "home/#about" });
      this.sendSmoothScrollEvent();
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../styles/navigation.scss";

.navbar {
  background: white;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95px;
  z-index: 5;
  @media (max-width: $maxWidth-breakpoint1) {
    height: 75px;
    justify-content: space-between;
  }
  &-flex {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 30px;
  }
  &-logo {
    display: block;
    width: 250px;
    @media (max-width: $maxWidth-breakpoint1) {
      width: 175px;
    }
  }
  &-logo:hover {
    cursor: pointer;
  }
}

.navbar-desktop {
  &-flex {
    display: flex;
    align-items: center;
  }
  &-links {
    display: flex;
    align-items: baseline;
    gap: 25px;
    list-style: none;
    font-weight: 600;
    margin: 0 35px;
    @media (max-width: $maxWidth-breakpoint1) {
      display: none;
    }
  }
  &-languages {
    ::v-deep button:nth-child(2).not-active-language {
      line-height: 22px;
    }
    @media (max-width: $maxWidth-breakpoint1) {
      display: none;
    }
  }
}

.navbar-mobile-toggle {
  width: 20px;
  height: 20px;
  cursor: pointer;
  @media (min-width: $minWidth-breakpoint1) {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  top: 75px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background-color: #004ea2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: $minWidth-breakpoint1) {
    display: none;
  }
  &-links {
    overflow-y: auto;
    list-style: none;
    font-weight: 600;
    padding: 0 10px 10px 10px;
    margin: 0 35px 15px 35px;
    text-align: center;
    li {
      margin-bottom: 10px;
    }
  }
}
</style>
