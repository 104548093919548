<template>
  <section class="contact">
    <div class="contact-img">
      <img
        src="@/assets/contact/map_en.jpg"
        width="100%"
        alt="map image english"
      />
    </div>
    <div class="contact-content">
      <div class="contact-body">
        <div class="contact-body-info">
          <h1>{{ $t("contact.contactUs") }}</h1>
          <p>{{ $t("contact.address") }}:</p>
          <p>{{ $t("contact.building") }}</p>
          <p v-show="showEnglishMap">{{ $t("contact.street") }}</p>
          <p v-show="showEnglishMap">{{ $t("contact.city") }}</p>
          <p v-show="showEnglishMap">{{ $t("contact.country") }}</p>
          <p class="last">{{ $t("contact.business") }}</p>
          <p>{{ $t("contact.email") }}office@gecelltx.com</p>
          <p>{{ $t("contact.phone") }}021-55696288</p>
        </div>
        <div class="contact-body-footer">
          <p>© 2022 上海精缮生物科技有限责任公司</p>
          <p>GeCell Therapeutics, LLC</p>
          <div class="contact-body-footer-links">
            <a
              href="https://wap.scjgj.sh.gov.cn/businessCheck/verifKey.do?showType=extShow&serial=9031000020210419161558000007587440-SAIC_SHOW_310000-20220920115014621002&signData=MEUCIHmNeWO8+4f/dzQmQbugof25S+7mitEDCTrkNk+/8YouAiEAk5y2Ju1Dd5QAjD9evnwlVGbxJWB7PekjYzCuDKl1ijg="
              target="_blank"
            >
              <img src="@/assets/footer/footer.png" alt="footer image" />
            </a>
            <a
              href="https://beian.miit.gov.cn/#/Integrated/index"
              target="_blank"
            >
              <p>沪ICP备18015983号-1</p>
            </a>
          </div>
          <div class="contact-body-footer-links">
            <a
              href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010902003417"
              target="_blank"
            >
              <img src="@/assets/footer/footer2.png" alt="footer image 2" />
              <p>沪公网安备31010902003417号</p>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="contact-footer">
      <p>© 2022 上海精缮生物科技有限责任公司</p>
      <p>GeCell Therapeutics, LLC</p>
      <div class="contact-footer-links">
        <a
          href="https://wap.scjgj.sh.gov.cn/businessCheck/verifKey.do?showType=extShow&serial=9031000020210419161558000007587440-SAIC_SHOW_310000-20220920115014621002&signData=MEUCIHmNeWO8+4f/dzQmQbugof25S+7mitEDCTrkNk+/8YouAiEAk5y2Ju1Dd5QAjD9evnwlVGbxJWB7PekjYzCuDKl1ijg="
          target="_blank"
        >
          <img src="@/assets/footer/footer.png" alt="footer image" />
        </a>
        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">
          <p>沪ICP备18015983号-1</p>
        </a>
      </div>
      <div class="contact-footer-links">
        <a
          href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010902003417"
          target="_blank"
        >
          <img src="@/assets/footer/footer2.png" alt="footer image 2" />
          <p>沪公网安备31010902003417号</p>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Contact",
  computed: {
    showEnglishMap() {
      return this.$i18n.locale === "en";
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../styles/navigation.scss";

.contact {
  background-color: #282626;
  display: flex;
  flex-direction: column;
  &-img {
    order: 2;
    img {
      aspect-ratio: 0.89 / 1;
    }
  }
  &-content {
    margin: 0 30px;
    order: 1;
  }
  &-footer {
    margin: 30px;
    order: 3;
    &-links {
      display: flex;
      align-items: center;
    }
    p:nth-child(1) {
      margin-right: 10px;
    }
    p {
      font-size: 12px;
      color: #fff;
      font-weight: 500;
      display: inline-block;
    }
    img {
      width: 13px;
      height: 15px;
      margin-right: 10px;
    }
    a {
      text-decoration: none;
      display: flex;
      align-items: center;
    }
  }
  &-body {
    color: #fff;
    &-info {
      margin: 60px 0;
      h1 {
        margin: 60px 0 30px 0;
        font-size: 26px;
      }
      p {
        font-size: 18px;
      }
      p.last {
        margin-top: 30px;
      }
    }
    &-footer {
      display: none;
    }
  }

  @media (min-width: 1001px) {
    flex-direction: row;
    &-img {
      flex: 1;
      order: 1;
      overflow: hidden;
      img {
        height: 100%;
      }
    }
    &-content {
      flex: 1;
      order: 2;
      margin: 0;
    }
    &-body {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &-info {
        margin: 100px 100px 50px 100px;
        h1 {
          font-size: 30px;
          margin: 0 0 50px 0;
        }
        p {
          font-size: 22px;
        }
      }
      &-footer {
        display: block;
        margin: 0 100px 100px 100px;
        font-size: 12px;
        &-links {
          display: flex;
          align-items: center;
        }
        img {
          width: 13px;
          height: 15px;
          margin-right: 10px;
        }
        a {
          text-decoration: none;
          color: #fff;
          display: flex;
          align-items: center;
        }
        p:nth-child(1) {
          margin-right: 10px;
        }
        p {
          display: inline-block;
        }
      }
    }
    &-footer {
      display: none;
    }
  }
}
</style>
