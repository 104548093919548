<template>
  <div class="languages" :class="{ mobile: isMobile }">
    <button @click.prevent="changeLanguage('en')" :class="classes('en')">
      ENG
    </button>
    <button @click.prevent="changeLanguage('ch')" :class="classes('ch')">
      简体
    </button>
  </div>
</template>

<script>
import t from "vue-types";
export default {
  name: "NavigationLanguages",
  props: {
    isMobile: t.bool.def(false),
  },
  computed: {
    currentLanguage() {
      return this.$i18n.locale;
    },
  },
  methods: {
    classes(language) {
      const active = this.currentLanguage === language;
      return {
        "active-language": active,
        "not-active-language": !active,
      };
    },
    changeLanguage(language) {
      if (this.currentLanguage === language) return;
      this.$i18n.locale = language;
      localStorage.setItem("i18n.locale", this.$i18n.locale);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../styles/navigation.scss";
.languages.mobile::after {
  background: white;
  height: 24px;
}
.languages.mobile {
  gap: 10px;
  button {
    font-size: 22px;
    color: white;
  }
}
.languages::after {
  content: "";
  background: $color-black;
  width: 2px;
  height: 20px;
  rotate: 15deg;
}
.languages button:nth-child(2) {
  order: 2;
}
.languages {
  display: flex;
  align-items: center;
  gap: 5px;
  button {
    align-self: flex-end;
    font-weight: 600;
    font-size: $size-desktop;
    border: none;
    background: transparent;
    color: $color-black;
  }
  button:hover {
    color: $color-grey-hover;
    cursor: pointer;
  }
  button.active-language {
    color: $color-yellow-active;
  }
}
</style>
