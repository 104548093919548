export default {
  navigation: {
    about: "ABOUT US",
    platform: "PLATFORM",
    news: "NEWS",
    team: "TEAM",
    pipeline: "PIPELINE",
    cmc: "CMC",
    publications: "PUBLICATIONS",
    career: "CAREERS",
    contact: "CONTACT",
  },
  home: {
    aboutUs: "About us",
    aboutUsParagraph:
      "In GeCell Therapeutics, we leverage the power of non-viral genetic engineering and gene delivery technologies to develop novel gene and cell therapies for genetic diseases and cancers.",
    ourPlatform: "OUR PLATFORM",
    ourPlatformHeading: "Genetic engineering with PiggyBac transposon",
    ourPlatformParagraph:
      "The PiggyBac transposon system is a safe and highly efficient genetic engineering approach for multiple types of cells with remarkable cost benefits. The large cargo capacity allows integration of multiple functional or regulatory elements for various purposes in a single-step engineering.",
    genePlatform: "Non-viral gene delivery with cationic polymers",
    genePlatformParagraph:
      "We have developed proprietary gene delivery technologies based on cationic polymers with validated nucleic acid delivery efficiency. The polymer platform also offers incredible versatility through structural modification for efficient encapsulation and delivery of various types of cargos (DNA, mRNA, siRNA, RNP, etc) in multiple types of cells or tissues.",
    latestNews: "LATEST NEWS",
    latestNewsArticleTitleTop: "GeCell Therapeutics:",
    latestNewsArticleTitleBottom:
      "Clinical Trial Approval of GC101 from China NMPA",
    latestNewsArticle:
      "GeCell Therapeutics, LLC*, (“GeCell”), has received the notification from the National Medical Products Administration of the People’s Republic of China regarding approval of the clinical trial of GC101 (the “Product”) for the graft treatment of incurable body wounds in patients with Recessive Dystrophic Epidermolysis Bullosa (RDEB).",
    moreNews: "MORE NEWS",
    cmc: "CMC",
    cmcParagraph:
      "GeCell therapeutics has cGMP compliant facility for process development and manufacture of NK cells derived from PBMC and iPSC.",
    cmcParagraphMiddle:
      "Our aim is to develop off-the-shelf immunotherapy product against blood and solid tumors. The production of NK cells involves sophisticated steps, including gene editing, gene delivery by PiggyBac transposase and cell differentiation and maturation.",
    cmcParagraphLast:
      "By the end of 2023, we will enter a new facility, a 4000-square-meter complex, located at Shanghai Zhangjiang district. The facility will support our iPSC-derived NK R&D and, more importantly, expand our manufacture capacity.",
    morePublications: "MORE PUBLICATIONS",
  },
  news: {
    gck01Trial:
      "Congratulations to launching the Investigator Initiated Clinical Trial of GCK01!",
    donation: {
      title:
        'Dr. Jin Yi, CEO of GeCell Therapeutics, was invited to participate in the "Love is Not Rare" charity dinner and contributed to this public welfare activity on behalf of the company.',
      info1:
        'On February 25, 2023, the "2023 Ruiou New Year Charity Dinner" hosted by Ruiou Public Welfare Foundation was warmly opened in Xixi, Hangzhou. With the theme of "Love is Not Rare", 100 caring people from all walks of life was invited to participate.',
      info2:
        'Dr. Jin Yi, CEO of GeCell Therapeutics, was invited to attend the dinner and donated more than 60,000 yuan to the charity fund on behalf of the company, which will be used for the funding of Ruio\'s 2023 project "Rare Diseases" scientific research and the foundation operations.',
    },
    award:
      'Congratulations to GeCell Therapeutics for receiving the honorary title of "Hongkou District Innovative Company" in Shanghai 2022!',
    article1: {
      title:
        "GeCell Therapeutics: Clinical Trial Approval of GC101 from China NMPA",
      info: "GeCell Therapeutics, LLC*, (“GeCell”), a subsidiary of Shanghai Fosun Pharmaceutical (Group) Co., Ltd. * (the “Company” and, together with its subsidiaries/units, the “Group”), has received the notification from the National Medical Products Administration of the People’s Republic of China regarding approval of the clinical trial of GC101 (the “Product”) for the graft treatment of incurable body wounds in patients with Recessive Dystrophic Epidermolysis Bullosa (RDEB). GeCell intends to commence the Phase I clinical trial of the Product in China (excluding Hong Kong, Macau and Taiwan for the purpose of this announcement, the same applies below) when the conditions are fulfilled.",
    },
    expo: {
      title:
        "GeCell Therapeutics debuted at BEYOND International Science and Technology Innovation Expo",
      info1:
        "The deepening of drug regulatory reform has accelerated the transformation of China's pharmaceutical industry towards high-quality development, which has given rise to the research, development, and transformation of high-value innovative drugs. At present, a new round of scientific and technological revolution and industrial upgrading is in the making, and domestic lead innovative biotech and pharmaceutical companies have taken improving the ability of independent research and development and original innovation as the development goal of consolidating long-term competitiveness.",
      info2: `On the "BEYOND Expo 2023" held from May 10 to 12, Fosun Pharmaceutical, which has participated in the exhibition for the third consecutive year, joined hands with Fuhong Hanlin, Fosun Kate, Fosun Health and deep incubation platform Fosun Lingzhi - GeCell Therapeutics, and debuted in the life science exhibition area. It exhibited the independent research and development achievements focusing on cutting-edge scientific and technological innovation, as well as the layout of the medical and health industry ecosystem.`,
      info3:
        "In November 2022, the Investigational New Drug application of GC101, an engineered cell therapy product independently developed by GeCell Therapeutics, was approved by the Center for Drug Evaluation of NMPA (CDE). The product is a Class 1 therapeutic biological product, which can be used for transplantation treatment of difficult-to-heal body surface wounds in patients with recessive dystrophic epidermolysis bullosa (RDEB). So far, there is no similar product on market in the world for the treatment of RDEB.",
      info4: `Dr. Jin Yi, the Chief Executive Officer of GeCell Therapeutics, said the company can make targeted insertion of large sized DNA fragment up to 11.8Kb into genome through its proprietary gene writing technology -ACTinG. "Not only is this technology capable of treating more previously untreatable genetic diseases, but it will also greatly improve the efficiency of genetic engineering in cell therapy. At present, GeCell Therapeutics has built a team with full product development capability and process development system and its production capability has been recognized by CDE as the first product IND application was approved last November."`,
      info5: `In the view of the industry, building-up genome engineering capabilities around RDEB, a field of rare diseases in early stage of the company development, can quickly "graft" the technologies to new disease indications in the future, with good commercialization prospects.`,
    },
    mtb: {
      title:
        "Uniting in heart and gathering up strength · building the future - The Perfect Blossoming of the 2023 Company Team Building Activity",
      info1: `To promote a good work-life balance, create a passionate, engaging, and happy working atmosphere, on May 12-14, 2023, the company organized a team building activity "Uniting in heart and gathering up strength · building the future", aiming to enrich employees' afterwork time, further strengthen the team's cohesion and enhance the solidarity and collaborative spirit among the team. The scene of the event was passionate, warm, and harmonious. In each event, the employees cooperated tacitly, carried forward the spirit of selfless dedication, solidarity, and cooperation, helped and encouraged each other, and showed their youthful passion to the fullest.`,
      info2:
        "As the saying goes, a single thread does not make a line, a single tree does not make a forest! The same piece of iron, can be sawed and melted, can also be refined into steel; The same team, can do nothing but achieve great things. There are various roles in a team, everyone must find their own position, because there is no perfect individual, only a perfect team!",
    },
  },
  team: {
    ourTeam: "OUR TEAM",
    readBio: "READ BIO",
    member1: {
      name: "Tian Xu, PhD",
      position: "Scientific Founder",
      about:
        "Tian is a scientific founder of GeCell Therapeutics. He is Chair Professor of Genetics and Vice President of Westlake University and Adjunct Professor of Genetics at Yale University. Prior of joining Westlake, he has been an endowed Professor at Yale Medical School and an Investigator of the Howard Hughes Medical Institute for more than two decades. Dr. Xu’s work focuses on mechanisms of growth control and new genetic research tools. His studies have not only elucidated fundamental principles of developmental biology, but also revealed pathogenic mechanisms, and contributed to the development of multiple drugs and to cancer and rare disease diagnosis. His lab first demonstrated the use of PiggyBac transposons in genetic engineering in mammals. He received his BS from Fudan University and PhD from Yale University.",
    },
    member2: {
      name: "Xiaoyang Wu, PhD",
      position: "Scientific Co-Founder",
      about:
        "Xiaoyang is a scientific co-founder of GeCell. He is currently the Associate Professor in the Ben May Department of Cancer Research at University of Chicago. His research focuses on stem cells and the applications in human diseases. He is the recipient of the Cancer Research Foundation Young Investigator Award, the V scholar award, the American Cancer Society research scholar award, and The Janet D. Rowley Discovery Fund award. He received his BS from Nanjing University and PhD from Cornell University.",
    },
    member3: {
      name: "Yi/Ye Jin, PhD",
      position: "CEO",
      about:
        "Yi has more than 20 years R&D experience on drug discovery and development. She held various scientific and management leadership positions with multiple biotech/pharmaceutical companies, including Berlex, Bayer Healthcare Pharmaceuticals (US), Second Genome, Chempartner, GenScript and Genchem. Before joining GeCell, Dr. Jin Yi served as CSO in GenChem and senior directior of R&D in Genscript. She has extensive preclinical research experience on autoimmune diseases, hemophilia and immuno-oncology. She received her BS from Si Chuan University, PhD from Iowa State University and Postdoc training at University of California, Berkeley.",
    },
    member4: {
      name: "Jingxian Liu, PhD",
      position: "CSO",
      about:
        "Jingxian brings more than 15 years of expertise in immunology and NK cell biology to GeCell. He has been the Director, Biologics Discovery in ChemPartner and Head of TCR discovery in Cytovant Sciences before joining GeCell. He received his BS and MS from Xi’an Jiaotong University and the PhD in Biochemistry from National University of Singapore, followed by a postdoctoral training in immunology focusing on mechanisms of NK and T cell functions in A*STAR Singapore.",
    },
    member5: {
      name: "Hui Fan, PhD",
      position: "CTO",
      about:
        "Hui leads the CMC development in GeCell. He has extensive experience in CMC developments of plasmid, virus, and cell therapy products, leading to sucessful IND application of first TCR-T therapy in China. He received his Bachelor of Medicine from the Tongji Medical College of Huazhong University of Science and Technology and PhD degree from Nanyang Technological University in Singapore, followed by postdoctoral training in University of California San Diego and Institute of Molecular and Cell Biology (IMCB), Singapore.",
    },
    member6: {
      name: "Weili Cong, PhD",
      position: "Director of Process Development",
      about:
        ">10 years of research experience on iPS and cell therapy development. PhD degree from Yokohama City University.",
    },
    member7: {
      name: "Zheng Zhang, PhD",
      position: "Director of QA",
      about:
        "10 years of experience on quality management of blood products; BS and PhD Shanghai Jiaotong University.",
    },
  },
  pipeline: {
    pipeline: "PIPELINE",
    project: "PROJECT",
    indication: "INDICATION",
    target: "TARGET",
    mechanism: "MECHANISM",
    discovery: "DISCOVERY",
    preclinical: "PRECLINICAL",
    ind: "IND",
    phaseOneOfTwo: "PHASE 1/2",
    pivotal: "PIVOTAL",
    geneticDisease: "GENETIC DISEASE",
    immunoOncology: "IMMUNO - ONCOLOGY",
    gc101: "GC-101",
    gcb202: "GCB-202",
    gck01: "GCK-01",
    gck02: "GCK-02",
    gck03: "GCK-03",
    gck05: "GCK-05",
    gck06: "GCK-06",
    rdeb: "RDEB",
    bcl: "BCL",
    aml: "AML",
    gbm: "GBM",
    gc: "GC",
    hcc: "HCC",
    col7a1: "COL7A1",
    cd20: "CD20",
    cd33cll1: "CD33/CLL1",
    her2: "Her2, EphA2, EGFRvIII, IL13Rα2",
    ct83: "CT83",
    mica: "MICA",
    cellTherapy: "Cell Therapy",
    geneTherapy: "Gene Therapy",
    pbNkAb: "PB-NK+Ab",
    carInk: "CAR-iNK",
  },
  career: {
    careers: "CAREERS",
    careersParagraph:
      "We seek highly motivated talents to join our team to make tangible impact to the lives with incurable diseases. If you are interested in exploring opportunities with GeCell, please send your CV to joinus@gecelltx.com.",
    readMore: "READ MORE",
    responsibilities: "Responsibilities",
    requirements: "Requirements",
    job1: {
      jobTitle: "Scientist/Senior Scientist/Principal Scientist of Immunology",
      jobResponsibility1:
        "Responsible for the planning and implementation of the cellular immuno-oncology (CAR-NK) program;",
      jobResponsibility2:
        "Build the CAR-NK immuno-oncology platform, including vector design, synthetic biology, vector/target screening and validation, assay development, etc.;",
      jobResponsibility3:
        "Formulate study plans, analyze data, write study summary/reports; present data internally or externally;",
      jobResponsibility4:
        "Participate in company pipeline planning, follow the latest academic/industry research progresses, propose innovative therapeutic options for new indications and design R&D plans;",
      jobResponsibility5:
        "Coordinate collaborations with Process Development, Quality, and Clinical research teams to promote therapy development;",
      jobResponsibility6: "Manage collaborations with CROs.",
      jobRequirement1:
        "PhD or postdoc from immunology (or immuno-oncology) background, with extensive training and related research experience;",
      jobRequirement2:
        "Extensive experience with molecular biology and cell biology techniques, perform bench-work or guide technicians on related experiments;",
      jobRequirement3:
        "Extensive experience with animal studies, perform or guide technicians on in vivo experiments;",
      jobRequirement4:
        "Quick learning capabilities with great passions on new research areas and technologies, capable to adopt latest methodologies/technologies to internal projects through literature research or collaborations;",
      jobRequirement5:
        "A well-planned, result-oriented team player, with sound managing capabilities and communication skills to lead a research team (varied sizes depends on roles).",
    },
    job2: {
      jobTitle: "Process Development (Cell therapy) Specialist",
      jobResponsibility1:
        "Responsible for operations of routine cell experiments, including cell transfection, expansion and culture; gene expression validation (including detection by molecular biology methods, such as Western blot, IF, IP, qPCR, etc.);",
      jobResponsibility2:
        "Record, organize and archive experimental data; submit R&D report and work summary;",
      jobResponsibility3: "Daily maintenance of laboratory and GMP facility;",
      jobResponsibility4:
        "Assist superior managers to complete the basic training of the experimental assistant's job skills.",
      jobRequirement1:
        "Master degrees in molecular biology, cell biology, biotechnology and other related majors;",
      jobRequirement2:
        "Familiar with cell and molecular biology techniques (cell culture, gene cloning, vector construction, etc.), familiar with the use of relevant databases (NCBI, etc.);",
      jobRequirement3:
        "Experience in stem cell differentiation and maturation is preferred. Experience in cell therapy (CAR-T, TCR-T) process development is preferred.",
    },
    job3: {
      jobTitle: "Immunology Specialist",
      jobResponsibility1:
        "Responsible for cell culturing, including cell sorting, cryopreservation and recovery;",
      jobResponsibility2:
        "Responsible for in vitro cell based functional analysis experiments, including cell detection, cell phenotyping, cytotoxicity assay, cytokine release, etc.;",
      jobResponsibility3:
        "Responsible for molecular biology related experiments, Western-blot, RT-PCR, immunohistochemistry, flow cytometry, etc.;",
      jobResponsibility4:
        "Responsible for experimental data recording and analysis.",
      jobRequirement1:
        "Master degrees in molecular biology, cell biology, immunology, basic medicine, etc.;",
      jobRequirement2:
        "Proficient in experimental skills in molecular biology (such as: Western-blot, RT-PCR, immunohistochemistry, flow cytometry, etc.);",
      jobRequirement3:
        "Proficient in in vitro and in vivo efficacy study related to cell therapy;",
      jobRequirement4:
        "Proactive, conscientious, strong sense of responsibility, and good teamwork spirit.",
    },
    job4: {
      jobTitleItalic: "In Vivo",
      jobTitle: "Pharmacology Scientist/Specialist",
      jobResponsibility1:
        "Establish animal disease models, develop in vivo drug evaluation methods, screen and verify drug candidates;",
      jobResponsibility2:
        "Support company's new drug research and development, assist or be responsible for DMPK experimental design and data analysis;",
      jobResponsibility3:
        "Execute routine animal experiments with existing animal models to assist pharmacological evaluation;",
      jobResponsibility4:
        "Responsible for the daily work of animal administration, observation, anesthesia, sampling, blood collection, dissection, surgery and so on;",
      jobResponsibility5:
        "Optimize the experimental method design according to the preclinical efficacy study protocol;",
      jobResponsibility6:
        "Organize and analyze the experimental data to form a research (experimental) report.",
      jobRequirement1:
        "Master degree in biology, (basic or clinical) medicine, pharmacology and other disciplines, including clinical pharmacology, pharmacokinetics, pathology, physiology, cell biology and other biomedical related majors;",
      jobRequirement2:
        "Proficient in operation of experimental animals, with rich experience in handling rat and mice;",
      jobRequirement3:
        "Experience with cell culture, cell function assays, etc.;",
      jobRequirement4:
        "Familiar with establishment and evaluation of animal disease models and cell culture techniques;",
      jobRequirement5:
        "Capable of independent experiment design, solving problems and scientific inferences;",
      jobRequirement6:
        "Strong interest in drug research and development, work proactively, have a rigorous attitude, a strong sense of responsibility and a good team spirit.",
    },
    job5: {
      jobTitle: "Molecular Biologist",
      jobResponsibility1:
        "Proficient in molecular biology experiments such as genomic DNA, plasmid extraction, PCR manipulation, etc. and can independently make plasmid construction and other works after training;",
      jobResponsibility2:
        "Ensure the integrity, timeliness and traceability of experimental data records;",
      jobResponsibility3:
        "Be able to analyze and judge problems that occur in the work, and report to the superior in time;",
      jobResponsibility4: "Complete other tasks arranged by superiors.",
      jobRequirement1:
        "Master's degrees on Biotechnology, molecular biology, biochemistry, cell biology, biological engineering and other related majors with solid professional knowledge;",
      jobRequirement2:
        "Lab experience relevant to vector construction and molecular cloning; ",
      jobRequirement3:
        "Familiar with techniques such as primer design, PCR operation, plasmid extraction and understand the relevant experimental principles; experience in Western blot, ELISA, cell therapy research, etc. is preferred;",
      jobRequirement4:
        "Be able to analyze experimental data and complete experimental records and reports;",
      jobRequirement5:
        "Good English reading and writing and communication skills.",
    },
    job6: {
      jobTitle: "Analytical Development, Quality Control Specialist/Scientist",
      jobResponsibility1:
        "Support method development and validation for cell and gene therapy, including molecular biology, cell biology, physicochemical analysis, or biosafety-related methodologies;",
      jobResponsibility2:
        "Draft and improve SOP, verification schemes, verification reports and other documents according to requirements of pharmacopoeia and other relevant laws and regulations;",
      jobResponsibility3:
        "Participate in writing of documents for IND applications;",
      jobResponsibility4:
        "Be able to analyze and judge the problems that occur in the work, and report to the superior in time; Regular reporting on the progress of relevant work.",
      jobRequirement1:
        "Master's degree in molecular biology, cell biology and other related majors, with solid professional knowledge;",
      jobRequirement2:
        "Proficient in molecular biology (such as qPCR, ELISA, western blot), cell biology (cell culture, flow cytometry) or physical and chemical analysis related experimental methods;",
      jobRequirement3:
        "Experience in the development of analytical methods is preferred;",
      jobRequirement4:
        "Good English reading and writing and communication skills;",
      jobRequirement5:
        "Proactive work, rigorous attitude, strong sense of responsibility, good team spirit.",
    },
    job7: {
      jobTitle: "Quality Assurance Specialist",
      jobResponsibility1:
        "Participate in the establishment of the quality management system and document control system of the R&D laboratories and GMP manufacturing plant;",
      jobResponsibility2:
        "Participate in the establishment of the quality management system and document control system for cell and gene therapy treatment products, and participate in the formulation, review and implementation of quality system documents;",
      jobResponsibility3:
        "Track and study policies, regulations and management systems of CDE and FDA on cell and gene therapy products to ensure that the company's research and development and testing comply with GxP specifications;",
      jobResponsibility4:
        "Responsible for supervising and carrying out daily quality management work;",
      jobResponsibility5:
        "Responsible for the training of internal personnel QA system.",
      jobRequirement1: "Bachelor degree or above in biology or pharmacy;",
      jobRequirement2:
        "Familiar with GMP and laws and regulations related to cell and gene therapy products;",
      jobRequirement3:
        "More than 1 year working experience in QA management of biological drugs or cell therapy products;",
      jobRequirement4:
        "Strong sense of responsibility and rigorous logical thinking ability;",
      jobRequirement5:
        "Excellent communication and coordination and problem-solving skills;",
      jobRequirement6: "Excellent English literacy and communication skills.",
    },
    job8: {
      jobTitle: "Project Manager",
      jobResponsibility1:
        "Organize and formulate project development plan and timeline, track project progress; participate in project meetings, and write meeting summaries and action items;",
      jobResponsibility2:
        "Organize and participate in meetings on new project initiation, progress review and completion/termination; coordinate and provide solutions to problems occurred in the progress of the project;",
      jobResponsibility3:
        "Participate in project application (IND), material procurement, and use electronic management system to track project progress; Responsible for the data collection and archiving of new drug research and development projects; organize R&D personnel to write patents;",
      jobResponsibility4:
        "Assist in the annual project budget planning and spending review.",
      jobRequirement1:
        "More than 2 years of experience in drug-related R&D project management; Experience on gene and cell therapy is preferred;",
      jobRequirement2:
        "Familiar with relevant CDE regulations, including GLP, GMP, GCP and other related laws and regulations; ",
      jobRequirement3:
        "Familiar with the market, competitive landscape of gene and cell therapy drug research and development;",
      jobRequirement4:
        "Strong organization, coordination ability and good sense of teamwork;",
      jobRequirement5: "Master's degree or above.",
    },
    job9: {
      jobTitle: "Regulatory Affairs Manager",
      jobResponsibility1:
        "New drug registration including developing and implementing a holistic plan, preparing and submission of clinical trial application registration materials within the prescribed or agreed time frame; capable of critically analyzing the compliance of package, finding out discrepancies with regulatory requirements and preparing supplementary data to meet regulatory requirements; Ensure timely and professional response to inquiries issued by the regulatory authorities related to the Company's products; Maintenance in accordance with relevant regulations and the requirements of the company's SOP; Keep abreast of the latest relevant laws and regulations in China; Provide training to the team when necessary;",
      jobResponsibility2:
        "Build relationships with relevant government agencies and corporate support teams to ensure regulatory consistency and expedite approvals; Good understanding of the regulations; Provide necessary and timely regulatory support to cross-functional teams; Actively collect information and monitor changes in the regulatory environment;",
      jobResponsibility3:
        "Teamwork spirit. Strengthen contact with the company's support team through various tools or communication channels during day-to-day operations; Ensure effective communication with other functional teams within the company.",
      jobRequirement1: "Bachelor or above in pharmacology or medicine;",
      jobRequirement2:
        "Work experience: at least 5 years of work experience in the pharmaceutical or biotech industry; At least 3 years of experience in the registered field, clinical registration and independent submission experience is preferred;",
      jobRequirement3:
        "Other requirements: good office software skills, including EXCEL, POWERPOINT and WORD; Good interpersonal communication skills; Teamwork spirit.",
      jobRequirement3eng:
        "Language requirements: English speaking and writting skills;",
    },
    job10: {
      jobTitle: "Medical Affairs Manager",
      jobResponsibility1:
        "Responsible for writing clinical plans and clinical trial related materials;",
      jobResponsibility2: "Complete the summary report of clinical research;",
      jobResponsibility3:
        "Review relevant documents of clinical trials, including but not limited to: CRF, data statistical analysis plan, data management plan, drug safety report management plan, etc., to ensure that it is completed before the relevant nodes of clinical trials;",
      jobResponsibility4:
        "In the clinical trial stage, complete the medical review, timely discover the problems in the research and solve them;",
      jobResponsibility5:
        "Responsible for the research and development of product related literature brief;",
      jobResponsibility6:
        "Formulate clinical research and development plan and complete clinical research design;",
      jobResponsibility7: "Complete clinical materials in IND or NDA;",
      jobResponsibility8:
        "Solve the problems related to medicine in clinical trials, and ensure that clinical research is carried out in strict accordance with the research plan and ICH-GCP, relevant national regulations, and company SOPs.",
      jobRequirement1:
        "Master's degree or above in medical related majors; Gene therapy and cancer cell therapy project background;",
      jobRequirement2:
        "Excellent Chinese and English writing ability and clear oral expression ability, good at communication;",
      jobRequirement3:
        "Strong ability to work independently and have good team spirit;",
      jobRequirement4:
        "Strong adaptability and can withstand reasonable task pressure; Strong sense of time, able to reasonably plan time and complete the work on time within the planned time limit;",
      jobRequirement5:
        "Familiar with the Drug Administration Law, New Drug Approval Measures, Drug Registration Management Measures, ICH-GCP, the whole process of clinical trials, and the development and status of clinical research at home and abroad.",
    },
    job11: {
      jobTitle: "Nanomedicine for Gene Therapy - Research Associates",
      jobResponsibility1:
        "Assist the development and verification of nucleic acid delivery systems. The materials are mainly novel polymeric vectors, also including but not limited to lipid-, peptide/protein-, or polysaccharide-based gene delivery vectors and their formulations;",
      jobResponsibility2:
        "Assist the preparation, formulation optimisation, process study and stability test of gene-delivering nanomedicine; ",
      jobResponsibility3:
        "Characterise gene delivering nanomedicines and evaluate their in vitro and in vivo efficiency;",
      jobResponsibility4:
        "Record, organise and analyse experimental data following internal quality standards and prepare research reports.",
      jobRequirement1:
        "Bachelor’s degree or above: pharmaceutical, chemistry, polymer materials, biotechnology, bioengineering or related majors;",
      jobRequirement2:
        "Preferred: knowledge and experience in drug preparation and nano-drug R&D, especially polymeric nanomaterials for drugs or nucleic acids delivery;",
      jobRequirement3:
        "Preferred: knowledge and experience in studying nucleic acid delivery materials; being familiar with material synthesis or relevant physicochemical and biological characterisation techniques (e.g. entrapment efficacy, size distribution and surface properties analysis, in vitro and in vivo delivery efficiency analysis);",
      jobRequirement4:
        "Preferred: knowledge and experience in microfluidic systems or other nano-drug formulation systems;",
      jobRequirement5:
        "Proficient in English literature retrieval and reading;",
      jobRequirement6:
        "With a professional dedication and teamwork consciousness.",
    },
  },
  corporate: {
    vision: "Corporate Vision",
    visionParagraph:
      "Vision: To become an innovative gene and cell therapy company",
    visionParagraph2: "Mission: Writing healthy lives",
    visionParagraph3:
      "Quality Assurance: Strive for quality excellence in developing innovative gene and cell therapies to improve and cure human diseases",
    values: "Core Values",
    valuesParagraph:
      "Patient-oriented and innovation-driven research and development",
    culture: "Corporate Culture",
    cultureParagraph: "Keep improving for the best",
  },
  contact: {
    contactUs: "CONTACT US",
    address: "Address",
    building: "Building #1",
    street: "1111 North Zhongshan 1st Road",
    city: "Shanghai 200437",
    country: "China",
    business: "Business: ",
    email: "E-mail: ",
    phone: "Phone: ",
  },
};
