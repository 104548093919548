<template>
  <section class="team">
    <div class="align-center">
      <div class="container">
        <h1 class="team-heading">{{ $t("team.ourTeam") }}</h1>
        <div class="team-content">
          <team-panel-collapsable
            v-for="(member, index) in members"
            :key="member.id"
            :member="member"
            :class="{ 'no--line': members.length - 1 === index }"
            @open="openPanel"
            @close="closePanel"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TeamPanelCollapsable from "./TeamPanelCollapsable";

const DATA_MEMBERS = [
  {
    id: 1,
    name: "team.member1.name",
    position: "team.member1.position",
    about: "team.member1.about",
    aboutCh: "team.member1.aboutCh",
    image: require("../../assets/team/team1.png"),
    isExpanded: false,
  },
  {
    id: 2,
    name: "team.member2.name",
    position: "team.member2.position",
    about: "team.member2.about",
    aboutCh: "team.member2.aboutCh",
    image: require("../../assets/team/team2.png"),
    isExpanded: false,
  },
  {
    id: 3,
    name: "team.member3.name",
    position: "team.member3.position",
    about: "team.member3.about",
    image: require("../../assets/team/team3.png"),
    isExpanded: false,
  },
  {
    id: 4,
    name: "team.member4.name",
    position: "team.member4.position",
    about: "team.member4.about",
    image: require("../../assets/team/team4.png"),
    isExpanded: false,
  },
  {
    id: 5,
    name: "team.member5.name",
    position: "team.member5.position",
    about: "team.member5.about",
    image: require("../../assets/team/team5.png"),
    isExpanded: false,
  },
  {
    id: 6,
    name: "team.member6.name",
    position: "team.member6.position",
    about: "team.member6.about",
    image: require("../../assets/team/team6.png"),
    isExpanded: false,
  },
  {
    id: 7,
    name: "team.member7.name",
    position: "team.member7.position",
    about: "team.member7.about",
    image: require("../../assets/team/team7.png"),
    isExpanded: false,
  },
];

export default {
  name: "Team",
  components: {
    TeamPanelCollapsable,
  },
  data() {
    return {
      members: [...DATA_MEMBERS],
    };
  },
  methods: {
    openPanel(id) {
      this.members = this.members.map((member) => ({
        ...member,
        isExpanded: member.id === id,
      }));
    },
    closePanel() {
      this.members = [...DATA_MEMBERS];
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../styles/navigation.scss";

.team {
  background-color: #f7f7f7;
  &-heading {
    margin: 60px 0;
    font-size: 26px;
  }
  &-content {
    margin-bottom: 60px;
  }
  @media (min-width: 1001px) {
    &-heading {
      margin: 100px 0 50px;
      font-size: 30px;
    }
    &-content {
      margin-bottom: 100px;
    }
  }
}
</style>
