<template>
  <footer class="footer">
    <div class="footer-info">
      <p>© 2022 上海精缮生物科技有限责任公司</p>
      <p>GeCell Therapeutics, LLC</p>
    </div>
    <div class="footer-logo">
      <div class="footer-logo-group">
        <a
          href="https://wap.scjgj.sh.gov.cn/businessCheck/verifKey.do?showType=extShow&serial=9031000020210419161558000007587440-SAIC_SHOW_310000-20220920115014621002&signData=MEUCIHmNeWO8+4f/dzQmQbugof25S+7mitEDCTrkNk+/8YouAiEAk5y2Ju1Dd5QAjD9evnwlVGbxJWB7PekjYzCuDKl1ijg="
          target="_blank"
        >
          <img src="@/assets/footer/footer.png" alt="footer image" />
        </a>
        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">
          <p>沪ICP备18015983号-1</p>
        </a>
      </div>
      <div class="footer-logo-group">
        <a
          href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010902003417"
          target="_blank"
        >
          <img src="@/assets/footer/footer2.png" alt="footer image 2" />
          <p>沪公网安备31010902003417号</p>
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "ApplicationFooter",
};
</script>

<style scoped lang="scss">
.footer {
  background-color: #282626;
  padding: 30px;
  &-info p:nth-child(1) {
    margin-right: 10px;
  }
  p {
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    display: inline-block;
  }
  &-logo {
    display: flex;
    flex-direction: column;
    &-group {
      display: flex;
      align-items: center;
    }
    a {
      display: flex;
      align-items: center;
      text-decoration: none;
    }
    img {
      width: 13px;
      height: 15px;
      margin-right: 10px;
    }
  }

  @media (min-width: 1001px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    &-logo {
      display: flex;
      flex-direction: row;
      align-items: center;
      &-group:first-child {
        margin-right: 20px;
      }
    }
  }
}
</style>
