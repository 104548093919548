<template>
  <div class="panel">
    <div v-if="!member.isExpanded" class="panel-heading" @click="toggleMember">
      <div class="panel-heading-content">
        <div class="panel-heading-content-name">
          {{ $t(member.name) }}
        </div>
        <div class="panel-heading-content-position">
          {{ $t(member.position) }}
        </div>
      </div>
      <div class="panel-heading-action">
        <button>{{ $t("team.readBio") }}</button>
      </div>
    </div>
    <transition-expand
      @open="enableSmoothScroll"
      @open-loaded="onPanelOpenLoaded"
      @close="enableSmoothScroll"
    >
      <div
        v-if="member.isExpanded"
        class="panel-body"
        :aria-hidden="`${!member.isExpanded}`"
        ref="memberPanel"
      >
        <div class="panel-body-heading">
          <div class="panel-body-heading-member">
            <div class="panel-body-heading-member-image">
              <img :src="member.image" alt="member picture" />
            </div>
            <div class="panel-body-heading-member-info">
              <h2>{{ $t(member.name) }}</h2>
              <h2>{{ $t(member.position) }}</h2>
            </div>
          </div>
          <div class="panel-body-heading-action">
            <button @click="toggleMember"></button>
          </div>
        </div>
        <div class="panel-body-content">
          <p
            v-if="member.aboutCh && !showEnglishTranslation"
            class="text-justify"
          >
            {{ $t(member.aboutCh) }}
          </p>
          <p class="text-justify">
            {{ $t(member.about) }}
          </p>
        </div>
      </div>
    </transition-expand>
  </div>
</template>

<script>
import t from "vue-types";
import TransitionExpand from "../TransitionExpand.vue";

export default {
  name: "TeamPanelCollapsable",
  components: {
    TransitionExpand,
  },
  props: {
    member: t.object.isRequired,
  },
  computed: {
    showEnglishTranslation() {
      return this.$i18n.locale === "en";
    },
  },
  methods: {
    onPanelOpenLoaded() {
      this.enableSmoothScroll();
      window.scrollTo({
        top: this.$refs.memberPanel.offsetTop - this.getPageOffsetToSubtract(),
        behavior: "smooth",
      });
    },
    enableSmoothScroll() {
      window.dispatchEvent(
        new CustomEvent("smooth-scroll", {
          detail: { smoothScroll: true },
        })
      );
    },
    toggleMember() {
      if (this.member.isExpanded) this.$emit("close");
      else this.$emit("open", this.member.id);
    },
    getPageOffsetToSubtract() {
      const isShowingNavbar = JSON.parse(localStorage.getItem("showNavbar"));
      const isMobileNavigation = JSON.parse(localStorage.getItem("isMobile"));
      const mobileNavigationOffset = isMobileNavigation ? 75 : 95;
      return isShowingNavbar ? mobileNavigationOffset : 0;
    },
  },
};
</script>

<style scoped lang="scss">
.panel {
  border-bottom: 1px solid #fbbe02;
  + .no--line {
    border: none;
  }
  &-heading:hover {
    cursor: pointer;
  }
  &-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    font-size: 18px;
    &-content-name {
      max-width: 350px;
      width: 100%;
      margin-bottom: 10px;
      margin-right: 15px;
    }
    &-content-position {
      max-width: 500px;
      width: 100%;
      margin-right: 15px;
    }
    &-action button {
      width: 20px;
      height: 20px;
      background: url("../../assets/plus_icon.svg");
      color: #004ea2;
      border: none;
      font-size: 0;
      font-weight: 600;
    }
    &-action button:hover {
      cursor: pointer;
    }
  }

  &-body {
    padding: 30px 0;
    &-heading {
      display: flex;
      justify-content: space-between;
      &-member-image img {
        max-width: 165px;
        width: 100%;
      }
      &-member-info {
        margin: 30px 0;
        h2 {
          font-size: 22px;
          font-weight: 500;
        }
      }
      &-action button {
        width: 20px;
        height: 20px;
        background: url("../../assets/x_icon.svg");
        border: none;
      }
      &-action button:hover {
        cursor: pointer;
      }
    }
    &-content {
      margin-bottom: 60px;
    }
  }

  @media (min-width: 750px) {
    &-heading {
      padding: 30px 0;
      font-size: 22px;
      &-content {
        width: 100%;
        display: flex;
      }
      &-content-name {
        margin-bottom: 0;
      }
      &-action {
        display: flex;
        justify-content: center;
        max-width: 150px;
        width: 100%;
      }
      &-action button {
        font-size: 18px;
        width: auto;
        height: auto;
        background: transparent;
      }
    }
    &-body {
      &-heading {
        &-member {
          display: flex;
          align-items: center;
          margin: 0 40px 40px 40px;
        }
        &-member-image img {
          margin-right: 40px;
        }
        &-action {
          display: flex;
          justify-content: center;
          max-width: 150px;
          width: 100%;
        }
      }
      &-content {
        font-size: 18px;
        margin-bottom: 80px;
        p {
          margin-left: 245px;
        }
      }
    }
  }
}
</style>
