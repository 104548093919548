<template>
  <div class="publications-app">
    <div class="publications">
      <div class="img">
        <img
          src="@/assets/publications/publications_banner_gecell.jpg"
          alt="publications image"
          width="100%"
        />
      </div>
      <div class="align-center">
        <div class="container">
          <div class="publications-wrapper">
            <div class="publications-left">
              <article
                v-for="(
                  publication, index
                ) in orderedPublications.leftPublications"
                :key="publication.id"
                class="publications-left-pub"
                :ref="index === 0 ? 'publication' : null"
              >
                <div class="publications-left-pub-image">
                  <img
                    :src="publication.image"
                    alt="publication 1 image"
                    width="100%"
                    @load="checkPublicationHeight(index)"
                  />
                  <div class="publications-left-pub-image-line"></div>
                </div>
                <div class="publications-left-pub-content">
                  <h1>PUBLICATION:</h1>
                  <h2>
                    <i>{{ publication.title }}</i>
                  </h2>
                  <p class="text-justify-en" v-html="publication.paragraph"></p>
                  <p>
                    <i>
                      {{ publication.authors }}
                    </i>
                  </p>
                  <a :href="publication.link" target="_blank">
                    SEE PUBLICATION
                  </a>
                </div>
              </article>
            </div>
            <div class="publications-right">
              <article
                v-for="(
                  publication, index
                ) in orderedPublications.rightPublications"
                :key="publication.id"
                :style="
                  index === 0
                    ? { marginTop: `${secondPublicationTopSpace}px` }
                    : null
                "
                class="publications-right-pub"
              >
                <div class="publications-right-pub-image">
                  <img
                    :src="publication.image"
                    alt="publication 1 image"
                    width="100%"
                  />
                  <div class="publications-right-pub-image-line"></div>
                </div>
                <div class="publications-right-pub-content">
                  <h1>PUBLICATION:</h1>
                  <h2>
                    <i>{{ publication.title }}</i>
                  </h2>
                  <p class="text-justify-en" v-html="publication.paragraph"></p>
                  <p>
                    <i>
                      {{ publication.authors }}
                    </i>
                  </p>
                  <a :href="publication.link" target="_blank">
                    SEE PUBLICATION
                  </a>
                </div>
              </article>
            </div>
          </div>
          <article
            v-for="publication in publications"
            :key="publication.id"
            class="publications-pub"
          >
            <img
              :src="publication.image"
              alt="publication 1 image"
              width="100%"
            />
            <div class="publications-pub-content">
              <h1>PUBLICATION:</h1>
              <h2>
                <i>{{ publication.title }}</i>
              </h2>
              <p class="text-justify-en" v-html="publication.paragraph"></p>
              <p>
                <i>
                  {{ publication.authors }}
                </i>
              </p>
              <a :href="publication.link" target="_blank"> SEE PUBLICATION </a>
            </div>
          </article>
        </div>
      </div>
    </div>
    <ApplicationFooter />
  </div>
</template>

<script>
import ApplicationFooter from "@/components/footer/ApplicationFooter";
const PUBLICATION_DATA = [
  {
    id: 1,
    title: "GeCell Therapeutics 2023",
    paragraph:
      "Accurate Integration of PiggyBac Transposon into Defined Genome Locus by using Engineered PBase fused to Cas9",
    authors:
      "Xu Naiqing, Hu Xiaohan, Fang Yuan, Liu Jingxian, Sun Kailiang, Xu Tian.",
    image: require("../assets/publications/publications12_gecell.jpg"),
    link: "./pdfs/GeCell_publication_poster_1.pdf",
  },
  {
    id: 2,
    title: "GeCell Therapeutics 2023",
    paragraph:
      "Treatment of Recessive Dystrophic Epidermolysis Bullosa by Transplantation of <i>COL7A1</i> Transgenic Tissue-engineered Skin Grafts",
    authors:
      "Xiaogang Deng, Weili Cong, Zhiming Chen, Zheng Zhang, Xian Liu, Wenjing Bai, Yijun Li, Xi Wang, Yingcun Wang, Qianqian Chen, Liwen Zhang, Xihui Gu, Yi Zhang, Kailiang Sun, Xiaoyang Wu, Yong Yang, Tian Xu.",
    image: require("../assets/publications/publications11_gecell.jpg"),
    link: "./pdfs/GeCell_publication_poster_2.pdf",
  },
  {
    id: 3,
    title: "Nat Commun. 2019",
    paragraph:
      "Highly branched poly (β-amino ester) delivery of minicircle DNA for transfection of neurodegenerative disease related cells",
    authors:
      "Liu S., Gao Y., Zhou D., Zeng M., Alshehri F., Newland B., et al.",
    image: require("../assets/publications/publications1_gecell.jpg"),
    link: "https://pubmed.ncbi.nlm.nih.gov/31341171/",
  },
  {
    id: 4,
    title: "Nat Biomed Eng. 2019",
    paragraph:
      "Genome-edited skin epidermal stem cells protect mice from cocaine-seeking behaviour and cocaine overdose",
    authors: "Li Y., Kong Q., Yue J., Gou X., Xu M., Wu X.",
    image: require("../assets/publications/publications2_gecell.jpg"),
    link: "https://pubmed.ncbi.nlm.nih.gov/30899600/",
  },
  {
    id: 5,
    title: "Proc Natl Acad Sci USA 2017",
    paragraph:
      "DNA-PK facilitates PiggyBac transposition by promoting paired-end complex formation",
    authors: "Jin Y., Chen Y., Zhao S., Guan K-L., Zhuang Y., Zhou W., et al.",
    image: require("../assets/publications/publications3_gecell.jpg"),
    link: "https://pubmed.ncbi.nlm.nih.gov/28645898/",
  },
  {
    id: 6,
    title: "Cell Stem Cell 2017",
    paragraph:
      "Engineered Epidermal Progenitor Cells Can Correct Diet-Induced Obesity and Diabetes",
    authors: "Yue J., Gou X., Li Y., Wicksteed B., Wu X.",
    image: require("../assets/publications/publications4_gecell.jpg"),
    link: "https://pubmed.ncbi.nlm.nih.gov/28777946/",
  },
  {
    id: 7,
    title: "Biomacromolecules 2016",
    paragraph:
      "Highly Branched Poly (β-amino esters) for Non-Viral Gene Delivery: High Transfection Efficiency and Low Toxicity Achieved by Increasing Molecular Weight",
    authors: "Jin Y., Chen Y., Zhao S., Guan K-L., Zhuang Y., Zhou W., et al.",
    image: require("../assets/publications/publications5_gecell.jpg"),
    link: "https://pubmed.ncbi.nlm.nih.gov/27641634/",
  },
  {
    id: 8,
    title: "Sci Adv. 2016",
    paragraph:
      "The transition from linear to highly branched poly (β-amino ester)s: Branching matters for gene delivery",
    authors:
      "Zhou D., Cutlar L., Gao Y., Wang W., O’Keeffe-Ahern J., McMahon S., et al.",
    image: require("../assets/publications/publications6_gecell.jpg"),
    link: "https://pubmed.ncbi.nlm.nih.gov/27386572/",
  },
  {
    id: 9,
    title: "Exp Dermatol. 2016",
    paragraph:
      "A non-viral gene therapy for treatment of recessive dystrophic epidermolysis bullosa",
    authors:
      "Cutlar L., Zhou D., Hu X., Duarte B., Greiser U., Larcher F., et al.",
    image: require("../assets/publications/publications7_gecell.jpg"),
    link: "https://pubmed.ncbi.nlm.nih.gov/27117059/",
  },
  {
    id: 10,
    title: "Chem Commun. 2015",
    paragraph:
      "Tailoring highly branched poly (β-amino ester)s: a synthetic platform for epidermal gene therapy",
    authors:
      "Huang J.-Y., Gao Y., Cutlar L., O’Keeffe-Ahern J., Zhao T., Lin F-H., et al.",
    image: require("../assets/publications/publications8_gecell.jpg"),
    link: "https://pubmed.ncbi.nlm.nih.gov/25892461/",
  },
  {
    id: 11,
    title: "Dis Model Mech 2013",
    paragraph:
      "PiggyBac as a high-capacity transgenesis and gene-therapy vector in human cells and mice",
    authors: "Li R., Zhuang Y., Han M., Xu T., Wu X.",
    image: require("../assets/publications/publications9_gecell.jpg"),
    link: "https://pubmed.ncbi.nlm.nih.gov/23519027/",
  },
  {
    id: 12,
    title: "Cell 2005",
    paragraph:
      "Efficient transposition of the PiggyBac (PB) transposon in mammalian cells and mice",
    authors: "Ding S., Wu X., Li G., Han M., Zhuang Y., Xu T.",
    image: require("../assets/publications/publications10_gecell.jpg"),
    link: "https://pubmed.ncbi.nlm.nih.gov/16096065/",
  },
];

export default {
  name: "Publications",
  components: {
    ApplicationFooter,
  },
  data() {
    return {
      secondPublicationTopSpace: "auto",
      publications: PUBLICATION_DATA,
    };
  },
  computed: {
    orderedPublications() {
      const leftPublications = this.publications.filter(
        (publication) => publication.id % 2 !== 0
      );
      const rightPublications = this.publications.filter(
        (publication) => publication.id % 2 === 0
      );
      return { leftPublications, rightPublications };
    },
  },
  mounted() {
    window.scrollTo({ top: 0 });
    window.addEventListener("resize", this.saveSecondPublicationHeight);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.saveSecondPublicationHeight);
  },
  methods: {
    saveSecondPublicationHeight() {
      const { height } = getComputedStyle(this.$refs.publication[0]);
      const withoutPx = height.split("px")[0];
      this.secondPublicationTopSpace = Number(withoutPx) / 2 + 100;
    },
    checkPublicationHeight(publication) {
      if (publication !== 0) return;
      this.saveSecondPublicationHeight();
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/navigation.scss";

.publications-app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}
.publications {
  background-color: #f2f2f2;
  flex: 1;
  > .img {
    margin-top: 95px;
    @media (max-width: $maxWidth-breakpoint1) {
      margin-top: 75px;
    }
    img {
      display: block;
    }
  }
  &-wrapper {
    display: none;
  }

  &-pub {
    margin: 30px 0;
    background-color: #fff;
    &-content {
      padding: 30px;
      h1,
      h2 {
        font-size: 26px;
      }
      p {
        font-size: 18px;
      }
      h2,
      p,
      a {
        margin-bottom: 30px;
      }
      a {
        border-radius: 15px;
        font-size: 14px;
        padding: 6px 13px;
        text-decoration: none;
        color: #000;
        background-color: #fff;
        border: 1px solid #fbbe02;
        font-weight: 600;
      }
      a:hover {
        cursor: pointer;
      }
    }
  }

  @media (min-width: 1001px) {
    &-pub {
      display: none;
    }
    &-wrapper::after {
      content: "";
      position: absolute;
      order: 2;
      width: 1px;
      top: 0;
      left: calc(50% - 0.5px);
      bottom: 0;
      background-color: #f9be00;
    }
    &-wrapper {
      display: flex;
      flex-wrap: wrap;
      position: relative;
    }
    &-left {
      flex: 1;
      &-pub {
        background-color: #fff;
        margin: 100px 50px 100px 0;
      }
      &-pub-image {
        position: relative;
        &-line {
          position: absolute;
          top: calc(50% - 0.5px);
          right: -50.5px;
          width: 100px;
          height: 1px;
          background-color: #f9be00;
        }
      }
      &-pub-image::after {
        content: "";
        position: absolute;
        top: calc(50% - 7.5px);
        right: 42.5px;
        width: 15px;
        height: 15px;
        background-color: #f9be00;
        border-radius: 50%;
      }
      &-pub-image::before {
        content: "";
        position: absolute;
        top: calc(50% - 7.5px);
        right: -57.5px;
        width: 15px;
        height: 15px;
        background-color: #f9be00;
        border-radius: 50%;
      }
      &-pub-content {
        margin: 50px;
        h1,
        h2 {
          font-size: 30px;
        }
        p {
          font-size: 21px;
        }
        h2,
        p,
        a {
          margin-bottom: 50px;
        }
        a {
          display: inline-block;
          border-radius: 22px;
          font-size: 18px;
          padding: 10px 18px;
          text-decoration: none;
          color: #000;
          background-color: #fff;
          border: 1px solid #fbbe02;
          font-weight: 600;
        }
        a:hover {
          cursor: pointer;
        }
      }
    }
    &-right {
      flex: 1;
      order: 3;
      &-pub {
        background-color: #fff;
        margin: 100px 0 100px 50px;
      }
      &-pub-image {
        position: relative;
        &-line {
          position: absolute;
          top: calc(50% - 0.5px);
          left: -50.5px;
          width: 100px;
          height: 1px;
          background-color: #f9be00;
        }
      }
      &-pub-image::after {
        content: "";
        position: absolute;
        top: calc(50% - 7.5px);
        left: 42.5px;
        width: 15px;
        height: 15px;
        background-color: #f9be00;
        border-radius: 50%;
      }
      &-pub-image::before {
        content: "";
        position: absolute;
        top: calc(50% - 7.5px);
        left: -57.5px;
        width: 15px;
        height: 15px;
        background-color: #f9be00;
        border-radius: 50%;
      }
      &-pub-content {
        margin: 50px;
        h1,
        h2 {
          font-size: 30px;
        }
        p {
          font-size: 21px;
        }
        h2,
        p,
        a {
          margin-bottom: 50px;
        }
        a {
          display: inline-block;
          border-radius: 22px;
          font-size: 18px;
          padding: 10px 18px;
          font-weight: 600;
          text-decoration: none;
          color: #000;
          background-color: #fff;
          border: 1px solid #fbbe02;
        }
        a:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
