<template>
  <section class="news">
    <div class="news-wrapper">
      <div class="news-wrapper-image">
        <img
          class="news-wrapper-logo-mobile"
          src="@/assets/home/news_mobile.jpg"
          width="100%"
          alt="news"
        />
        <img
          class="news-wrapper-logo"
          src="@/assets/home/news.jpg"
          alt="news"
        />
      </div>
      <div class="news-wrapper-content">
        <div class="news-wrapper-content-box">
          <h1>{{ $t("home.latestNews") }}</h1>
          <div>
            <h2>{{ $t("home.latestNewsArticleTitleTop") }}</h2>
            <h2 v-if="showEnglishTranslation">
              {{ $t("home.latestNewsArticleTitleBottom") }}
            </h2>
          </div>
          <p class="text-justify">
            {{ $t("home.latestNewsArticle") }}
          </p>
          <button @click="navigateToNews">
            {{ $t("home.moreNews") }}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "News",
  computed: {
    showEnglishTranslation() {
      return this.$i18n.locale === "en";
    },
  },
  methods: {
    navigateToNews() {
      this.$router.push({ name: "news", params: { noScroll: true } });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../styles/navigation.scss";

.news {
  &-wrapper {
    &-logo {
      display: none;
      aspect-ratio: 0.89 / 1;
    }
    &-logo-mobile {
      aspect-ratio: 3.57 / 1;
    }
    &-content {
      &-box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 60px 30px;
        h1,
        h2 {
          font-size: 26px;
        }
        h1,
        div,
        p {
          margin-bottom: 30px;
        }
        h2 {
          font-weight: 500;
        }
        p {
          font-size: 18px;
        }
        button {
          background-color: #fff;
          border: 1px solid #fbbe02;
          border-radius: 15px;
          font-size: 14px;
          width: 120px;
          height: 30px;
          color: #000;
          font-weight: 600;
        }
        button:hover {
          cursor: pointer;
          background-color: #f7f7f7;
        }
      }
    }
    @media (min-width: 1001px) {
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      &-image {
        img {
          width: 100%;
          object-fit: cover;
        }
      }
      &-logo-mobile {
        display: none;
      }
      &-logo {
        display: block;
      }
      &-image {
        flex: 1;
        background-color: #f7f7f7;
        img {
          height: 100%;
        }
      }
      &-content {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        &-box {
          max-width: 400px;
          width: 100%;
          margin: 30px 100px 30px 30px;
          h1,
          div,
          p {
            margin-bottom: 50px;
          }
          h1,
          h2 {
            font-size: 30px;
          }
          p {
            font-size: 21px;
          }
          button {
            border-radius: 20px;
            font-size: 18px;
            width: 150px;
            height: 40px;
          }
        }
      }
    }
  }
}
</style>
