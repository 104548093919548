<template>
  <div class="news-app">
    <div
      v-if="!isMobile && showPopup"
      class="popup"
      @click.self="onClickImage"
      title="Close popup"
    >
      <div class="popup-content" @click.self="onClickImage">
        <img
          :src="require(`@/assets/news/${popupImage}.jpg`)"
          title=""
          alt="news popup image"
          :class="popupImageClass"
        />
      </div>
    </div>
    <div class="news">
      <img
        src="@/assets/news/news_gecell.jpg"
        class="img__banner"
        alt="news image"
        width="100%"
      />
      <div v-if="!showEnglishTranslation" class="align-center">
        <div class="news-container container">
          <div class="news-group">
            <div class="news-card first">
              <div class="news-card-content">
                <h1>{{ $t("news.GCK01Offer.title") }}</h1>
                <p class="text-justify bottom-small">
                  {{ $t("news.GCK01Offer.info1") }}
                </p>
                <p class="text-justify bottom-small">
                  {{ $t("news.GCK01Offer.info2") }}
                </p>
                <p class="text-justify bottom-small">
                  {{ $t("news.GCK01Offer.info3") }}
                </p>
                <div class="GCK01-list">
                  <p class="bottom-small">
                    {{ $t("news.GCK01Offer.titleList") }}
                  </p>
                  <ul>
                    <li class="text-justify">
                      {{ $t("news.GCK01Offer.info1List") }}
                    </li>
                    <li class="text-justify">
                      {{ $t("news.GCK01Offer.info2List") }}
                    </li>
                    <li class="text-justify">
                      {{ $t("news.GCK01Offer.info3List") }}
                    </li>
                    <li class="text-justify">
                      {{ $t("news.GCK01Offer.info4List") }}
                    </li>
                    <li class="text-justify">
                      {{ $t("news.GCK01Offer.info5List") }}
                    </li>
                  </ul>
                </div>
                <p class="text-justify bottom-small">
                  {{ $t("news.GCK01Offer.info4") }}
                </p>
                <p class="text-justify bottom-small">
                  {{ $t("news.GCK01Offer.info5") }}
                </p>
                <p class="text-justify bottom-small">
                  {{ $t("news.GCK01Offer.info6") }}
                </p>
                <p class="text-justify no-margin">
                  {{ $t("news.GCK01Offer.info7") }}
                </p>
                <div class="GCK01-block">
                  <p class="text-justify no-margin">
                    {{ $t("news.GCK01Offer.info8Lines.line1") }}
                  </p>
                  <p class="text-justify no-margin">
                    {{ $t("news.GCK01Offer.info8Lines.line2") }}
                  </p>
                  <p class="text-justify no-margin">
                    {{ $t("news.GCK01Offer.info8Lines.line3") }}
                  </p>
                </div>
                <p class="text-justify no-margin">
                  {{ $t("news.GCK01Offer.info9") }}
                </p>
                <p class="text-justify no-margin GCK01-last">
                  {{ $t("news.GCK01Offer.info10") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="align-center">
        <div class="news-container container">
          <div class="news-group">
            <div class="news-card" :class="{ first: showEnglishTranslation }">
              <div class="news-card-content">
                <div class="gck01-trial-group">
                  <div class="gck01-trial-group-content">
                    <h1>{{ $t("news.gck01Trial") }}</h1>
                  </div>
                  <div class="gck01-trial-group-content">
                    <img
                      src="@/assets/news/gck01_trial.jpg"
                      :name="popupImageNames.gck01Trial"
                      alt="news gck01 trial image"
                      width="100%"
                      @click.prevent="onClickImage"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="align-center">
        <div class="news-container container">
          <div class="news-group">
            <div class="news-card">
              <div class="news-card-content">
                <h1>
                  {{ $t("news.mtb.title") }}
                </h1>
                <p class="text-justify bottom-small">
                  {{ $t("news.mtb.info1") }}
                </p>
                <p
                  class="text-justify"
                  :class="{ 'bottom-small': !showEnglishTranslation }"
                >
                  {{ $t("news.mtb.info2") }}
                </p>
                <p v-if="!showEnglishTranslation" class="text-justify">
                  {{ $t("news.mtb.info3") }}
                </p>
                <div class="mtb-images">
                  <img
                    src="@/assets/news/news_mtb.jpg"
                    alt="news team building the canoes"
                    :name="popupImageNames.newsMtb"
                    width="50%"
                    @click.prevent="onClickImage"
                  />
                  <img
                    src="@/assets/news/news_mtb2.jpg"
                    alt="news team building image"
                    :name="popupImageNames.newsMtb2"
                    width="50%"
                    @click.prevent="onClickImage"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="align-center">
        <div class="news-container container">
          <div class="news-group">
            <div class="news-card">
              <div class="news-card-content">
                <h1>
                  {{ $t("news.expo.title") }}
                </h1>
                <p class="text-justify bottom-small">
                  {{ $t("news.expo.info1") }}
                </p>
                <p class="text-justify bottom-small">
                  {{ $t("news.expo.info2") }}
                </p>
                <p class="text-justify bottom-small">
                  {{ $t("news.expo.info3") }}
                </p>
                <p class="text-justify bottom-small">
                  {{ $t("news.expo.info4") }}
                </p>
                <p class="text-justify">
                  {{ $t("news.expo.info5") }}
                </p>
                <div class="expo-images">
                  <img
                    src="@/assets/news/news_expo.jpg"
                    alt="news expo image"
                    width="50%"
                    :name="popupImageNames.newsExpo"
                    @click.prevent="onClickImage"
                  />
                  <img
                    src="@/assets/news/news_expo2.jpg"
                    alt="news expo image"
                    :name="popupImageNames.newsExpo2"
                    width="50%"
                    @click.prevent="onClickImage"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="align-center">
        <div class="news-container container">
          <div class="news-group">
            <div class="news-card">
              <div class="news-card-content">
                <div class="donation-group">
                  <div class="donation-group-content">
                    <img
                      src="@/assets/news/donation_certificate.jpg"
                      :name="popupImageNames.donationCertificate"
                      alt="news award image"
                      width="100%"
                      @click.prevent="onClickImage"
                    />
                  </div>
                  <div class="donation-group-content">
                    <h1>{{ $t("news.donation.title") }}</h1>
                    <p class="text-justify bottom-small">
                      {{ $t("news.donation.info1") }}
                    </p>
                    <p class="text-justify">{{ $t("news.donation.info2") }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="align-center">
        <div class="news-container container">
          <div class="news-group">
            <div class="news-card">
              <div class="news-card-content">
                <div class="award-group">
                  <div class="award-group-content">
                    <h1>{{ $t("news.award") }}</h1>
                  </div>
                  <div class="award-group-content">
                    <img
                      src="@/assets/news/award.jpg"
                      :name="popupImageNames.award"
                      alt="news award image"
                      width="100%"
                      @click.prevent="onClickImage"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="align-center">
        <div class="news-container container">
          <div class="news-group">
            <div class="news-card last">
              <div class="news-card-content">
                <h1>{{ $t("news.article1.title") }}</h1>
                <p class="text-justify">{{ $t("news.article1.info") }}</p>
                <a
                  :href="articleLink"
                  target="_blank"
                  :class="{ china: !showEnglishTranslation }"
                >
                  {{ $t("career.readMore") }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ApplicationFooter />
  </div>
</template>

<script>
import ApplicationFooter from "@/components/footer/ApplicationFooter";
export default {
  name: "News",
  components: {
    ApplicationFooter,
  },
  data() {
    return {
      showPopup: false,
      popupImage: "news_mtb",
      isMobile:
        (window.innerWidth || document.documentElement.innerWidth) < 700,
    };
  },
  watch: {
    isMobile: {
      handler(mobile) {
        if (!mobile) return;
        this.showPopup = false;
        document.querySelector("body").classList.remove("modal-open");
      },
      immediate: true,
    },
  },
  computed: {
    popupImageNames() {
      return {
        newsMtb: "news_mtb",
        newsMtb2: "news_mtb2",
        newsExpo: "news_expo",
        newsExpo2: "news_expo2",
        donationCertificate: "donation_certificate",
        award: "award",
        gck01Trial: "gck01_trial",
      };
    },
    showEnglishTranslation() {
      return this.$i18n.locale === "en";
    },
    articleLink() {
      return this.showEnglishTranslation
        ? "https://www1.hkexnews.hk/listedco/listconews/sehk/2022/1114/2022111401039.pdf"
        : "https://mp.weixin.qq.com/s/hTvTjIjM9F61U22Tr-MkBA";
    },
    popupImageClass() {
      switch (this.popupImage) {
        case this.popupImageNames.newsMtb:
        case this.popupImageNames.newsMtb2:
          return "mtb__image";
        case this.popupImageNames.newsExpo:
        case this.popupImageNames.newsExpo2:
          return "news__image";
        case this.popupImageNames.donationCertificate:
          return "donation__image";
        case this.popupImageNames.award:
          return "award__image";
        case this.popupImageNames.gck01Trial:
          return "gck01_trial";
        default:
          return "";
      }
    },
  },
  mounted() {
    window.scrollTo({ top: 0 });
    window.addEventListener("resize", this.onResizeIsMobile);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResizeIsMobile);
  },
  methods: {
    onResizeIsMobile() {
      this.isMobile =
        (window.innerWidth || document.documentElement.innerWidth) < 700;
    },
    onClickImage(el) {
      this.popupImage = el.target.name || this.popupImageNames.newsMtb;
      if (this.isMobile) {
        this.showPopup = false;
        return;
      }
      if (this.showPopup)
        document.querySelector("body").classList.remove("modal-open");
      else document.querySelector("body").classList.add("modal-open");
      this.showPopup = !this.showPopup;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/navigation.scss";

.popup {
  display: none;
}
@media (min-width: 700px) {
  .popup {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba($color: #999, $alpha: 0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    cursor: pointer;
    &-content {
      img {
        cursor: default;
        margin: 25px 0;
        width: 100%;
      }
      .mtb__image {
        max-width: 1064px;
        aspect-ratio: 1.46 / 1;
      }
      .news__image {
        max-width: 1064px;
        aspect-ratio: 1.41 / 1;
      }
      .award__image {
        max-width: 450px;
        aspect-ratio: 0.75 / 1;
      }
      .donation__image {
        max-width: 450px;
        aspect-ratio: 0.71 / 1;
      }
      .gck01_trial {
        max-width: 1064px;
        aspect-ratio: 1.06 / 1;
      }
    }
    @media (min-height: 700px) {
      align-items: center;
    }
  }
}

.news-app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}
.news {
  background-color: #f2f2f2;
  flex: 1;
  .img__banner {
    display: block;
    margin-top: 95px;
    @media (max-width: $maxWidth-breakpoint1) {
      margin-top: 75px;
    }
  }
  &-container {
    max-width: 1100px;
  }
  &-card {
    margin: 15px 0;
    background-color: #fff;
    &.first {
      margin-top: 30px;
    }
    &.last {
      margin-bottom: 30px;
    }
    &-content {
      padding: 30px;
      h1,
      p,
      li {
        margin-bottom: 30px;
      }
      .GCK01-last {
        margin-bottom: 0;
      }
      .GCK01-block {
        margin-bottom: 30px;
        p {
          margin: 0;
        }
      }
      ul {
        padding-left: 25px;
      }
      h1 {
        font-size: 26px;
        font-weight: 600;
      }
      p,
      li {
        font-size: 18px;
      }
      a {
        display: inline-block;
        border-radius: 15px;
        font-size: 14px;
        padding: 6px 18px;
        text-decoration: none;
        color: #000;
        background-color: #fff;
        border: 1px solid #fbbe02;
        font-weight: 600;
      }
      a:hover {
        cursor: pointer;
      }
      a.china {
        border-radius: 20px;
        padding: 5px 18px 7px 18px;
      }
    }
    .gck01-trial-group {
      @media (min-width: 700px) {
        display: flex;
        flex-direction: row;
        &-content {
          align-self: center;
          padding-right: 50px;
          h1 {
            margin-bottom: 0;
          }
          img {
            width: 370px;
          }
          &:first-child {
            order: 1;
            padding-right: 0;
          }
        }
      }
    }
    .award-group {
      display: flex;
      flex-direction: column;
      &-content {
        align-self: center;
      }
      &-content:last-child {
        max-width: 275px;
        width: 100%;
      }
    }
    @media (min-width: 700px) {
      .award-group {
        flex-direction: row;
        &-content {
          h1 {
            margin-bottom: 0;
          }
          img {
            width: 275px;
          }
          &:first-child {
            padding-right: 50px;
          }
        }
      }
      img {
        cursor: pointer;
      }
    }
    .donation-group {
      display: flex;
      flex-direction: column;
      &-content:first-child {
        order: 2;
        align-self: center;
        max-width: 275px;
        width: 100%;
      }
    }
    @media (min-width: 1001px) {
      margin: 50px 0;
      &.first {
        margin-top: 100px;
      }
      &.last {
        margin-bottom: 100px;
      }
      &-content {
        padding: 50px;
        h1,
        p {
          margin-bottom: 50px;
        }
        .GCK01-list {
          margin-bottom: 30px;
        }
        .GCK01-block {
          margin-bottom: 0;
          > p {
            display: inline-block;
          }
          > p:last-child {
            display: inline;
          }
        }
        li {
          margin-bottom: 0;
        }
        h1 {
          font-size: 30px;
        }
        p,
        li {
          font-size: 21px;
        }
        a {
          font-size: 18px;
          padding: 8px 18px;
          border-radius: 20px;
        }
        a.china {
          border-radius: 20px;
          padding: 5px 20px 7px 20px;
        }
      }
      .donation-group {
        flex-direction: row;
        &-content {
          p {
            margin-bottom: 0;
          }
          p.bottom-small {
            margin-bottom: 30px;
          }
          p:last img {
            width: 275px;
          }
          &:first-child {
            align-self: unset;
          }
          &:last-child {
            order: 2;
            padding-left: 50px;
          }
        }
      }
      .no-margin {
        margin: 0;
      }
    }
    @media (max-width: 700px) {
      .mtb-images > img,
      .expo-images > img {
        display: block;
        width: 100%;
      }
    }
    .bottom-small {
      margin-bottom: 30px;
    }
  }
}
</style>
