<template>
  <div>
    <section class="career">
      <div class="career-heading">
        <img
          src="@/assets/home/careers_gecell.jpg"
          alt="career logo"
          width="100%"
        />
        <div class="align-center">
          <div class="container">
            <h1>{{ $t("career.careers") }}</h1>
          </div>
        </div>
      </div>
      <div class="align-center">
        <div class="container">
          <div class="career-content">
            <p class="career-content-info text-justify">
              {{ $t("career.careersParagraph") }}
            </p>
  
            <article
              v-for="job in dataJobs"
              :key="job.id"
              class="career-job"
              :aria-hidden="`${!job.isExpanded}`"
              ref="careerPanel"
            >
              <div
                @click="onHandleJob(job)"
                :aria-hidden="`${!job.isExpanded}`"
                class="career-job-heading"
              >
                <h2 class="career-job-heading-mobile">
                  <i v-if="job.titleItalic && showEnglishTranslation">
                    {{ $t(job.titleItalic) }}
                  </i>
                  {{ $t(job.title) }}
                </h2>
                <h2 class="career-job-heading-desktop">
                  <i v-if="job.titleItalic && showEnglishTranslation">
                    {{ $t(job.titleItalic) }}
                  </i>
                  {{ $t(job.title) }}
                </h2>
                <button :aria-hidden="`${!job.isExpanded}`">
                  {{ $t("career.readMore") }}
                </button>
              </div>
              <transition-expand
                @open="enableSmoothScroll"
                @open-loaded="onPanelOpenLoaded"
                @close="enableSmoothScroll"
              >
                <div v-if="job.isExpanded" class="career-job-body">
                  <p
                    v-show="showEnglishTranslation"
                    class="career-job-body-info text-justify"
                  >
                    Roles and Responsibilities could be adjusted based on the
                    background and capability of individual candidate.
                  </p>
  
                  <h3>{{ $t("career.responsibilities") }}</h3>
                  <ol>
                    <li
                      v-for="(responsibility, index) in job.responsibilities"
                      :key="index"
                      class="text-justify"
                    >
                      {{ $t(responsibility) }}
                    </li>
                  </ol>
  
                  <h3>{{ $t("career.requirements") }}</h3>
                  <ol>
                    <li
                      v-for="(requirement, index) in job.requirements"
                      :key="index"
                      class="text-justify"
                    >
                      {{ $t(requirement) }}
                    </li>
                  </ol>
                </div>
              </transition-expand>
            </article>
          </div>
        </div>
      </div>
    </section>
    <corporate-vision />
  </div>
</template>

<script>
import TransitionExpand from "../TransitionExpand.vue";
import CorporateVision from "@/components/home/CorporateVision";

const DATA_JOBS = [
  {
    id: 1,
    title: "career.job1.jobTitle",
    isExpanded: false,
    responsibilities: [
      "career.job1.jobResponsibility1",
      "career.job1.jobResponsibility2",
      "career.job1.jobResponsibility3",
      "career.job1.jobResponsibility4",
      "career.job1.jobResponsibility5",
      "career.job1.jobResponsibility6",
    ],
    requirements: [
      "career.job1.jobRequirement1",
      "career.job1.jobRequirement2",
      "career.job1.jobRequirement3",
      "career.job1.jobRequirement4",
      "career.job1.jobRequirement5",
    ],
  },
  {
    id: 2,
    title: "career.job2.jobTitle",
    isExpanded: false,
    responsibilities: [
      "career.job2.jobResponsibility1",
      "career.job2.jobResponsibility2",
      "career.job2.jobResponsibility3",
      "career.job2.jobResponsibility4",
    ],
    requirements: [
      "career.job2.jobRequirement1",
      "career.job2.jobRequirement2",
      "career.job2.jobRequirement3",
    ],
  },
  {
    id: 3,
    title: "career.job3.jobTitle",
    isExpanded: false,
    responsibilities: [
      "career.job3.jobResponsibility1",
      "career.job3.jobResponsibility2",
      "career.job3.jobResponsibility3",
      "career.job3.jobResponsibility4",
    ],
    requirements: [
      "career.job3.jobRequirement1",
      "career.job3.jobRequirement2",
      "career.job3.jobRequirement3",
      "career.job3.jobRequirement4",
    ],
  },
  {
    id: 4,
    titleItalic: "career.job4.jobTitleItalic",
    title: "career.job4.jobTitle",
    isExpanded: false,
    responsibilities: [
      "career.job4.jobResponsibility1",
      "career.job4.jobResponsibility2",
      "career.job4.jobResponsibility3",
      "career.job4.jobResponsibility4",
      "career.job4.jobResponsibility5",
      "career.job4.jobResponsibility6",
    ],
    requirements: [
      "career.job4.jobRequirement1",
      "career.job4.jobRequirement2",
      "career.job4.jobRequirement3",
      "career.job4.jobRequirement4",
      "career.job4.jobRequirement5",
      "career.job4.jobRequirement6",
    ],
  },
  {
    id: 5,
    title: "career.job5.jobTitle",
    isExpanded: false,
    responsibilities: [
      "career.job5.jobResponsibility1",
      "career.job5.jobResponsibility2",
      "career.job5.jobResponsibility3",
      "career.job5.jobResponsibility4",
    ],
    requirements: [
      "career.job5.jobRequirement1",
      "career.job5.jobRequirement2",
      "career.job5.jobRequirement3",
      "career.job5.jobRequirement4",
      "career.job5.jobRequirement5",
    ],
  },
  {
    id: 6,
    title: "career.job6.jobTitle",
    isExpanded: false,
    responsibilities: [
      "career.job6.jobResponsibility1",
      "career.job6.jobResponsibility2",
      "career.job6.jobResponsibility3",
      "career.job6.jobResponsibility4",
    ],
    requirements: [
      "career.job6.jobRequirement1",
      "career.job6.jobRequirement2",
      "career.job6.jobRequirement3",
      "career.job6.jobRequirement4",
      "career.job6.jobRequirement5",
    ],
  },
  {
    id: 7,
    title: "career.job7.jobTitle",
    isExpanded: false,
    responsibilities: [
      "career.job7.jobResponsibility1",
      "career.job7.jobResponsibility2",
      "career.job7.jobResponsibility3",
      "career.job7.jobResponsibility4",
      "career.job7.jobResponsibility5",
    ],
    requirements: [
      "career.job7.jobRequirement1",
      "career.job7.jobRequirement2",
      "career.job7.jobRequirement3",
      "career.job7.jobRequirement4",
      "career.job7.jobRequirement5",
      "career.job7.jobRequirement6",
    ],
  },
  {
    id: 8,
    title: "career.job8.jobTitle",
    isExpanded: false,
    responsibilities: [
      "career.job8.jobResponsibility1",
      "career.job8.jobResponsibility2",
      "career.job8.jobResponsibility3",
      "career.job8.jobResponsibility4",
    ],
    requirements: [
      "career.job8.jobRequirement1",
      "career.job8.jobRequirement2",
      "career.job8.jobRequirement3",
      "career.job8.jobRequirement4",
      "career.job8.jobRequirement5",
    ],
  },
  {
    id: 9,
    title: "career.job9.jobTitle",
    isExpanded: false,
    responsibilities: [
      "career.job9.jobResponsibility1",
      "career.job9.jobResponsibility2",
      "career.job9.jobResponsibility3",
    ],
    requirements: [
      "career.job9.jobRequirement1",
      "career.job9.jobRequirement2",
      "career.job9.jobRequirement3",
    ],
  },
  {
    id: 10,
    title: "career.job10.jobTitle",
    isExpanded: false,
    responsibilities: [
      "career.job10.jobResponsibility1",
      "career.job10.jobResponsibility2",
      "career.job10.jobResponsibility3",
      "career.job10.jobResponsibility4",
      "career.job10.jobResponsibility5",
      "career.job10.jobResponsibility6",
      "career.job10.jobResponsibility7",
      "career.job10.jobResponsibility8",
    ],
    requirements: [
      "career.job10.jobRequirement1",
      "career.job10.jobRequirement2",
      "career.job10.jobRequirement3",
      "career.job10.jobRequirement4",
      "career.job10.jobRequirement5",
    ],
  },
  {
    id: 11,
    title: "career.job11.jobTitle",
    isExpanded: false,
    responsibilities: [
      "career.job11.jobResponsibility1",
      "career.job11.jobResponsibility2",
      "career.job11.jobResponsibility3",
      "career.job11.jobResponsibility4",
    ],
    requirements: [
      "career.job11.jobRequirement1",
      "career.job11.jobRequirement2",
      "career.job11.jobRequirement3",
      "career.job11.jobRequirement4",
      "career.job11.jobRequirement5",
      "career.job11.jobRequirement6",
    ],
  },
];

export default {
  name: "Career",
  components: {
    TransitionExpand,
    CorporateVision,
  },
  data() {
    return {
      jobs: [],
      id: null,
    };
  },
  watch: {
    showEnglishTranslation() {
      this.dataJobs = this.jobs;
    },
  },
  computed: {
    dataJobs: {
      get() {
        return this.jobs;
      },
      set(jobsList) {
        this.jobs = jobsList.map((job) => {
          if (job.id === 3) {
            const defaultResponsibilities = [
              "career.job3.jobResponsibility1",
              "career.job3.jobResponsibility2",
              "career.job3.jobResponsibility3",
              "career.job3.jobResponsibility4",
            ];
            const jobMod = {
              ...job,
              responsibilities: this.showEnglishTranslation
                ? defaultResponsibilities
                : [
                    "career.job3.jobResponsibility1ch",
                    ...defaultResponsibilities,
                  ],
            };
            return { ...jobMod };
          }
          if (job.id === 9) {
            const defaultRequirements = [
              "career.job9.jobRequirement1",
              "career.job9.jobRequirement2",
              "career.job9.jobRequirement3",
            ];
            const jobMod = {
              ...job,
              requirements: !this.showEnglishTranslation
                ? defaultRequirements
                : [
                    defaultRequirements[0],
                    defaultRequirements[1],
                    "career.job9.jobRequirement3eng",
                    defaultRequirements[2],
                  ],
            };
            return { ...jobMod };
          }
          return { ...job };
        });
      },
    },
    showEnglishTranslation() {
      return this.$i18n.locale === "en";
    },
  },
  mounted() {
    this.dataJobs = DATA_JOBS;
  },
  methods: {
    onPanelOpenLoaded() {
      this.enableSmoothScroll();
      window.scrollTo({
        top:
          this.$refs.careerPanel[this.id - 1].offsetTop -
          this.getPageOffsetToSubtract(),
        behavior: "smooth",
      });
    },
    enableSmoothScroll() {
      window.dispatchEvent(
        new CustomEvent("smooth-scroll", {
          detail: { smoothScroll: true },
        })
      );
    },
    onHandleJob({ id, isExpanded }) {
      if (!isExpanded) this.openJob(id);
      else this.dataJobs = DATA_JOBS;
    },
    openJob(id) {
      this.id = id;
      this.dataJobs = this.jobs.map((job) => ({
        ...job,
        isExpanded: job.id === id,
      }));
    },
    getPageOffsetToSubtract() {
      const isShowingNavbar = JSON.parse(localStorage.getItem("showNavbar"));
      const isMobileNavigation = JSON.parse(localStorage.getItem("isMobile"));
      const isMobileScreen =
        (window.innerWidth || document.documentElement.innerWidth) <= 1000;
      const mobileNavigationOffset = isMobileNavigation ? 75 : 95;
      const mobileScreenOffset = isMobileScreen ? 60 : 80;
      return isShowingNavbar
        ? mobileNavigationOffset + mobileScreenOffset
        : mobileScreenOffset;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../styles/navigation.scss";

.career {
  background-color: #fff;
  &-heading {
    img {
      aspect-ratio: 3.55 / 1;
    }
    h1 {
      font-size: 26px;
      margin-top: 60px;
    }
  }
  &-content {
    margin-bottom: 60px;
    &-info {
      font-size: 18px;
      margin: 30px 0 60px 0;
    }
  }
  &-job[aria-hidden="false"] {
    margin: 60px 0;
  }
  &-job {
    margin: 30px 0;
    &-heading:hover {
      cursor: pointer;
    }
    &-heading {
      &-desktop {
        display: none;
      }
      h2 {
        font-size: 18px;
        color: #004ea2;
      }
      h2:hover {
        cursor: pointer;
      }
      button {
        display: none;
      }
    }
    &-body {
      &-info {
        font-size: 18px;
        color: #999;
        margin: 30px 0;
      }
      h3 {
        font-size: 18px;
        margin: 30px 0;
        font-weight: 500;
      }
      ol {
        padding-left: 20px;
        li {
          font-size: 18px;
          margin: 30px 0;
          padding-left: 10px;
        }
      }
    }
  }

  @media (min-width: 1001px) {
    &-heading {
      position: relative;
      h1 {
        position: absolute;
        bottom: 100px;
        font-size: 30px;
        color: #fff;
      }
    }
    &-content {
      margin-bottom: 100px;
      &-info {
        font-size: 22px;
        margin: 100px 0;
      }
    }
    &-job[aria-hidden="false"] {
      margin: 80px 0;
      button {
        font-size: 0;
        width: 20px;
        height: 20px;
        background: url("../../assets/x_icon.svg") no-repeat;
      }
    }
    &-job {
      &-heading[aria-hidden="true"]:hover button,
      &-heading[aria-hidden="false"] button {
        display: block;
      }
      &-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-desktop {
          display: block;
        }
        &-mobile {
          display: none;
        }
        h2 {
          font-weight: 500;
          font-size: 22px;
          color: #000;
        }
        h2:hover {
          cursor: unset;
        }
        button {
          border: none;
          background-color: transparent;
          font-weight: 600;
          font-size: 18px;
          color: #004ea2;
          margin-left: 20px;
        }
        button:hover {
          cursor: pointer;
        }
      }
      &-body {
        &-info {
          margin: 50px 0;
        }
        h3 {
          font-size: 22px;
          margin: 50px 0;
        }
        ol {
          li {
            margin: 25px 0;
          }
        }
      }
    }
  }
}
</style>
