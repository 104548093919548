<template>
  <section class="pipeline">
    <div class="align-center">
      <div class="pipeline-container">
        <div class="pipeline-heading">
          <h1>{{ $t("pipeline.pipeline") }}</h1>
        </div>
        <div
          v-for="(pipeline, index) in pipelines"
          :key="index"
          class="pipeline-wrap"
        >
          <div
            class="pipeline-content-heading"
            :class="{ second: index !== 0 }"
          >
            <h2
              :class="{
                china: isChinaLanguage,
                blue: pipeline.backgroundColor === 'bg-blue',
              }"
            >
              {{ $t(`pipeline.${pipeline.title}`) }}
            </h2>
          </div>
          <div class="pipeline-content">
            <div class="pipeline-content-wrap">
              <div
                v-for="(line, lineIndex) in pipeline.lines"
                :key="lineIndex"
                class="pipeline-content-wrap-lines"
              >
                <div class="pipeline-content-description">
                  <div
                    class="pipeline-content-description-head"
                    :class="{ header: index === 0 && lineIndex === 0 }"
                  >
                    <h3
                      v-for="(head, headIndex) in line.description.head"
                      :key="headIndex"
                    >
                      {{ $t(`pipeline.${head}`) }}
                    </h3>
                  </div>
                  <div
                    :class="pipeline.backgroundColor"
                    class="pipeline-content-description-body"
                  >
                    <div
                      v-for="(body, headIndex) in line.description.body"
                      :key="headIndex"
                      class="pipeline-content-description-body-box"
                    >
                      <p :class="body.class">
                        {{ $t(`pipeline.${body.label}`) }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="pipeline-content-progress">
                  <div
                    class="pipeline-content-progress-head"
                    :class="{ header: index === 0 && lineIndex === 0 }"
                  >
                    <h3
                      v-for="(head, headIndex) in line.progress.head"
                      :key="headIndex"
                    >
                      {{ $t(`pipeline.${head}`) }}
                    </h3>
                  </div>
                  <div
                    :class="`${pipeline.backgroundColor}-progress`"
                    class="pipeline-content-progress-body"
                    :style="precentageProgressBar(line.progress.precentage)"
                  >
                    <div
                      v-for="(body, headIndex) in line.progress.body"
                      :key="headIndex"
                      class="pipeline-content-progress-body-box"
                      :class="body.class"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Pipeline",
  data() {
    return {
      pipelines: [
        {
          title: "geneticDisease",
          backgroundColor: "bg-yellow",
          lines: [
            {
              description: {
                head: ["project", "indication", "target", "mechanism"],
                body: [
                  { label: "gc101", class: "bigger bolder" },
                  { label: "rdeb", class: "" },
                  { label: "col7a1", class: "" },
                  { label: "cellTherapy", class: "different" },
                ],
              },
              progress: {
                precentage: "55%",
                head: [
                  "discovery",
                  "preclinical",
                  "ind",
                  "phaseOneOfTwo",
                  "pivotal",
                ],
                body: [
                  { class: "yellow" },
                  { class: "yellow" },
                  { class: "yellow" },
                  { class: "yellow" },
                  { class: "" },
                ],
              },
            },
            {
              description: {
                head: ["project", "indication", "target", "mechanism"],
                body: [
                  { label: "gcb202", class: "bigger bolder" },
                  { label: "rdeb", class: "" },
                  { label: "col7a1", class: "" },
                  { label: "geneTherapy", class: "different" },
                ],
              },
              progress: {
                precentage: "20%",
                head: [
                  "discovery",
                  "preclinical",
                  "ind",
                  "phaseOneOfTwo",
                  "pivotal",
                ],
                body: [
                  { class: "yellow" },
                  { class: "yellow" },
                  { class: "yellow" },
                  { class: "yellow" },
                  { class: "" },
                ],
              },
            },
          ],
        },
        {
          title: "immunoOncology",
          backgroundColor: "bg-blue",
          lines: [
            {
              description: {
                head: ["project", "indication", "target", "mechanism"],
                body: [
                  { label: "gck01", class: "bigger bolder" },
                  { label: "bcl", class: "" },
                  { label: "cd20", class: "" },
                  { label: "pbNkAb", class: "different" },
                ],
              },
              progress: {
                precentage: "30%",
                head: [
                  "discovery",
                  "preclinical",
                  "ind",
                  "phaseOneOfTwo",
                  "pivotal",
                ],
                body: [
                  { class: "blue" },
                  { class: "blue" },
                  { class: "blue" },
                  { class: "blue" },
                  { class: "" },
                ],
              },
            },
            {
              description: {
                head: ["project", "indication", "target", "mechanism"],
                body: [
                  { label: "gck02", class: "bigger bolder" },
                  { label: "aml", class: "" },
                  { label: "cd33cll1", class: "" },
                  { label: "carInk", class: "different" },
                ],
              },
              progress: {
                precentage: "20%",
                head: [
                  "discovery",
                  "preclinical",
                  "ind",
                  "phaseOneOfTwo",
                  "pivotal",
                ],
                body: [
                  { class: "blue" },
                  { class: "blue" },
                  { class: "blue" },
                  { class: "blue" },
                  { class: "" },
                ],
              },
            },
            {
              description: {
                head: ["project", "indication", "target", "mechanism"],
                body: [
                  { label: "gck03", class: "bigger bolder" },
                  { label: "gbm", class: "" },
                  { label: "her2", class: "her2" },
                  { label: "carInk", class: "different" },
                ],
              },
              progress: {
                precentage: "20%",
                head: [
                  "discovery",
                  "preclinical",
                  "ind",
                  "phaseOneOfTwo",
                  "pivotal",
                ],
                body: [
                  { class: "blue" },
                  { class: "blue" },
                  { class: "blue" },
                  { class: "blue" },
                  { class: "" },
                ],
              },
            },
            {
              description: {
                head: ["project", "indication", "target", "mechanism"],
                body: [
                  { label: "gck05", class: "bigger bolder" },
                  { label: "gc", class: "" },
                  { label: "ct83", class: "" },
                  { label: "carInk", class: "different" },
                ],
              },
              progress: {
                precentage: "20%",
                head: [
                  "discovery",
                  "preclinical",
                  "ind",
                  "phaseOneOfTwo",
                  "pivotal",
                ],
                body: [
                  { class: "blue" },
                  { class: "blue" },
                  { class: "blue" },
                  { class: "blue" },
                  { class: "" },
                ],
              },
            },
            {
              description: {
                head: ["project", "indication", "target", "mechanism"],
                body: [
                  { label: "gck06", class: "bigger bolder" },
                  { label: "hcc", class: "" },
                  { label: "mica", class: "" },
                  { label: "carInk", class: "different" },
                ],
              },
              progress: {
                precentage: "20%",
                head: [
                  "discovery",
                  "preclinical",
                  "ind",
                  "phaseOneOfTwo",
                  "pivotal",
                ],
                body: [
                  { class: "blue" },
                  { class: "blue" },
                  { class: "blue" },
                  { class: "blue" },
                  { class: "" },
                ],
              },
            },
          ],
        },
      ],
    };
  },
  computed: {
    isChinaLanguage() {
      return this.$i18n.locale !== "en";
    },
  },
  methods: {
    precentageProgressBar(precentage) {
      return {
        "--precentage": precentage,
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../styles/navigation.scss";

.bg-yellow {
  background-color: #fbbe02;
}
.bg-blue {
  background-color: #004ea2;
}
.bg-yellow-progress {
  background: linear-gradient(to right, #fbbe02, #fcee21);
}
.bg-blue-progress {
  background: linear-gradient(to right, #004ea2, #3fa9f5);
}

.pipeline {
  background-color: #b3b3b3;
  &-container {
    width: 100%;
    margin: 60px 30px 30px 30px;
  }
  &-heading {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    h1 {
      max-width: 1000px;
      width: 100%;
      font-size: 26px;
      color: #fff;
    }
  }
  &-content {
    &-heading {
      display: flex;
      justify-content: center;
      margin: 40px 0 30px 0;
      h2 {
        font-size: 18px;
        color: #fff;
        max-width: 1000px;
        width: 100%;
      }
    }
    &-description {
      &-head {
        display: flex;
        margin: 10px 0;
        h3 {
          width: calc(100% / 4);
          text-align: center;
          font-size: 10px;
          color: #fff;
        }
      }
      &-body {
        display: flex;
        height: 40px;
        &-box {
          width: calc(100% / 4);
          display: flex;
          align-items: center;
          justify-content: center;
          p {
            color: #fff;
            font-size: 11px;
            text-align: center;
          }
          p.her2 {
            line-height: 11px;
          }
          p.different {
            font-size: 11px;
          }
          p.bolder {
            font-weight: 600;
          }
          p.bigger {
            font-size: 13px;
          }
        }
      }
    }
    &-progress {
      &-head {
        display: flex;
        margin: 10px 0;
        h3 {
          width: calc(100% / 5);
          text-align: center;
          font-size: 10px;
          color: #fff;
        }
      }
      &-body::before {
        content: "";
        position: absolute;
        top: calc(50% - 3.5px);
        width: var(--precentage);
        height: 7px;
        background-color: #fff;
      }
      &-body::after {
        content: "";
        position: absolute;
        top: calc(50% - 2.5px);
        left: var(--precentage);
        width: 5px;
        height: 5px;
        background: linear-gradient(45deg, transparent 0 50%, #fff 50% 100%);
        transform-origin: center;
        transform: translateX(-2.5px) rotate(45deg);
      }
      &-body {
        position: relative;
        display: flex;
        margin-bottom: 30px;
        &-box {
          width: calc(100% / 5);
          height: 40px;
        }
        &-box.yellow {
          border-right: 1px solid #fcee21;
        }
        &-box.blue {
          border-right: 1px solid #3fa9f5;
        }
      }
    }
  }

  @media (min-width: 600px) and (max-width: 1000px) {
    &-content {
      &-description {
        &-head {
          h3 {
            font-size: 14px;
          }
        }
        &-body {
          &-box {
            p,
            p.different,
            p.bigger {
              font-size: 16px;
            }
            p.her2 {
              line-height: 14px;
            }
          }
        }
      }
      &-progress {
        &-head {
          h3 {
            font-size: 14px;
          }
        }
        &-body {
          &-box {
            p,
            p.different {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  @media (min-width: 1201px) {
    &-container {
      max-width: 1150px;
    }
    &-wrap {
      display: grid;
      grid-template-columns: 50px 1fr 50px;
    }
    &-content {
      &-heading {
        position: relative;
        margin: 0;
        h2 {
          transform: rotate(270deg);
          transform-origin: bottom;
          position: absolute;
          bottom: 45px;
        }
        h2.china {
          width: max-content;
          bottom: 55px;
        }
        h2.blue {
          color: #004ea2;
        }
      }
      &-heading.second {
        h2 {
          width: max-content;
          bottom: 140px;
        }
      }
    }
  }

  @media (min-width: 1001px) {
    &-container {
      margin: 100px 30px 100px 30px;
    }
    &-heading {
      margin-bottom: 50px;
      h1 {
        font-size: 30px;
      }
    }
    &-wrap {
      position: relative;
    }
    &-content {
      display: flex;
      justify-content: center;
      align-items: center;
      &-wrap {
        max-width: 1050px;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
      &-wrap-lines {
        display: flex;
        justify-content: center;
      }
      &-description {
        max-width: 500px;
        width: 100%;
        &-head {
          display: none;
          margin: 0 0 20px 0;
          h3 {
            font-size: 16px;
          }
        }
        &-head.header {
          display: flex;
        }
        &-body {
          height: 50px;
          &-box {
            p {
              font-size: 16px;
            }
            p.bigger {
              font-size: 18px;
            }
            p.different {
              font-size: unset;
            }
            p.her2 {
              line-height: 14px;
            }
          }
        }
      }
      &-progress {
        max-width: 550px;
        width: 100%;
        &-head {
          display: none;
          margin: 0 0 20px 0;
          h3 {
            font-size: 16px;
          }
        }
        &-head.header {
          display: flex;
        }
        &-body::before {
          top: calc(50% - 4.5px);
          height: 9px;
        }
        &-body::after {
          top: calc(50% - 3.5px);
          width: 7px;
          height: 7px;
          transform: translateX(-3.5px) rotate(45deg);
        }
        &-body {
          margin-bottom: 5px;
          &-box {
            height: 50px;
          }
        }
      }
    }
  }
}
</style>
