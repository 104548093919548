<template>
  <section class="platform">
    <div class="platform-second">
      <div class="platform-second-grid">
        <div class="platform-second-content">
          <div class="platform-second-content-box">
            <h1>{{ $t("home.ourPlatform") }}</h1>
            <h2>{{ $t("home.ourPlatformHeading") }}</h2>
            <p class="text-justify">
              {{ $t("home.ourPlatformParagraph") }}
            </p>
            <p v-show="showEnglishTranslation" class="text-justify">
              We are applying the PiggyBac platform to both
              <i>ex vivo</i>
              and
              <i>in vivo</i>
              gene therapy developments. At the same time, we are also modifying
              the PiggyBac transposase to enable site specific gene transfer.
            </p>
          </div>
        </div>

        <div class="platform-second-image">
          <div class="platform-second-image-pic"></div>
        </div>
      </div>
    </div>

    <div class="platform-third">
      <div class="platform-third-grid">
        <div class="platform-third-content">
          <div class="platform-third-content-box">
            <h1>{{ $t("home.genePlatform") }}</h1>
            <p class="text-justify">
              {{ $t("home.genePlatformParagraph") }}
            </p>
          </div>
        </div>

        <div class="platform-third-image">
          <div class="platform-third-image-pic"></div>
        </div>
      </div>
    </div>

    <div
      class="platform-bottom-image"
      :class="{ 'china-img': !showEnglishTranslation }"
    ></div>
  </section>
</template>

<script>
export default {
  name: "Platform",
  computed: {
    showEnglishTranslation() {
      return this.$i18n.locale === "en";
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../styles/navigation.scss";

.platform {
  background-color: #f7f7f7;
  &-second {
    &-content {
      display: flex;
      align-items: center;
      background-color: #fff;
      justify-content: center;
      &-box {
        margin: 60px 30px 30px 30px;
        h1,
        h2 {
          font-size: 26px;
          margin-bottom: 30px;
        }
        h2 {
          font-weight: 500;
        }
        p {
          font-size: 18px;
          margin-bottom: 30px;
        }
      }
    }
    &-image-pic {
      margin: 0 auto;
      background: url("../../assets/home/platform_gecell_mobile_2.png");
      aspect-ratio: 0.72 / 1;
    }
    @media (min-width: 1001px) {
      &-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
      &-content {
        grid-column: 1;
        justify-content: flex-end;
        &-box {
          margin: 30px 100px 30px 30px;
          max-width: 400px;
          width: 100%;
          h1,
          h2 {
            font-size: 30px;
            margin-bottom: 50px;
          }
          p {
            font-size: 21px;
          }
        }
      }
      &-image {
        grid-column: 2;
      }
      &-image-pic {
        margin: unset;
        background: url("../../assets/home/platform_gecell_2.png");
        aspect-ratio: 0.89 / 1;
      }
    }
  }
  &-third {
    &-grid::after {
      content: "";
      display: block;
      width: 100%;
      height: 5px;
      background-color: #fff;
    }
    &-content {
      display: flex;
      align-items: center;
      background-color: #fff;
      &-box {
        margin: 60px 30px;
        h1 {
          font-size: 26px;
          font-weight: 500;
          margin-bottom: 30px;
        }
        p {
          font-size: 18px;
        }
      }
    }
    &-image-pic {
      margin: 0 auto;
      background: url("../../assets/home/platform_gecell_mobile_3.png");
      aspect-ratio: 0.59 / 1;
    }
    @media (min-width: 1001px) {
      &-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
      &-grid::after {
        display: none;
      }
      &-content {
        grid-column: 2;
        order: 2;
        justify-content: flex-start;
        &-box {
          margin: 30px 30px 30px 100px;
          max-width: 400px;
          width: 100%;
          h1,
          h2 {
            font-size: 30px;
            margin-bottom: 50px;
          }
          p {
            font-size: 21px;
          }
        }
      }
      &-image {
        grid-column: 1;
        order: 1;
      }
      &-image-pic {
        margin: unset;
        background: url("../../assets/home/platform_gecell_3.png");
        aspect-ratio: 0.89 / 1;
        float: right;
      }
    }
  }
  &-bottom-image.china-img {
    background: url("../../assets/home/platform_gecell_4_ch.png");
    background-size: cover;
    @media (max-width: 1000px) {
      background: url("../../assets/home/platform_gecell_mobile_4_ch.png");
      background-size: cover;
      aspect-ratio: 0.74 / 1;
    }
  }
  &-bottom-image {
    background: url("../../assets/home/platform_gecell_4.png");
    aspect-ratio: 1.95 / 1;
    @media (max-width: 1000px) {
      background: url("../../assets/home/platform_gecell_mobile_4.png");
      aspect-ratio: 0.67 / 1;
    }
  }
  &-bottom-image {
    max-width: 1600px;
    width: 100%;
    background-size: cover;
    margin: 0 auto;
    @media (max-width: 1000px) and (min-width: 500px) {
      width: 65%;
    }
  }
  &-second-image-pic,
  &-third-image-pic {
    max-width: 800px;
    width: 100%;
    background-size: cover;
    @media (max-width: 1000px) and (min-width: 500px) {
      width: 65%;
    }
  }
}
</style>
