<template>
  <section class="about">
    <div class="about-video">
      <video
        ref="aboutVideo"
        @timeupdate.prevent="loopVideo"
        muted
        playsinline
        autoplay
      >
        <source src="@/assets/home/DnaVideo.mp4" type="video/mp4" />
        Sorry, your browser doesn't support embedded videos.
      </video>
    </div>
    <div class="align-center">
      <div class="about-content container">
        <h1>{{ $t("home.aboutUs") }}</h1>
        <p class="text-justify">
          {{ $t("home.aboutUsParagraph") }}
        </p>
      </div>
    </div>
    <div class="about-bottom-wrap">
      <div class="about-bottom-wrap-image"></div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutUs",
  mounted() {
    this.$nextTick(() => this.playVideo());
  },
  methods: {
    loopVideo({ target }) {
      if (target.duration - target.currentTime >= 0.1) return;
      this.$refs.aboutVideo.play();
    },
    playVideo() {
      const videoEl = this.$refs.aboutVideo;
      videoEl.controls = false;
      videoEl.play();
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../styles/navigation.scss";

.about {
  background-color: #fbbe02;
  &-video {
    background-color: #fff;
    display: flex;
    align-items: flex-end;
    video {
      width: 100%;
      margin-top: 95px;
      object-fit: contain;
      background-color: #000;
      aspect-ratio: 3.37 / 1;
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 30px;
    h1 {
      font-size: 26px;
      font-weight: 600;
      margin-top: 60px;
      margin-bottom: 30px;
    }
    p {
      font-size: 18px;
      margin-bottom: 60px;
    }
  }

  &-bottom-wrap {
    background-color: #f7f7f7;
  }
  &-bottom-wrap-image {
    background: url("../../assets/home/platform_gecell_1.png");
    aspect-ratio: 1.95 / 1;
    @media (max-width: 1000px) {
      background: url("../../assets/home/platform_gecell_mobile_1.png");
      aspect-ratio: 0.52 / 1;
    }
  }
  &-bottom-wrap-image {
    max-width: 1600px;
    width: 100%;
    background-size: cover;
    margin: 0 auto;
    @media (max-width: 1000px) and (min-width: 500px) {
      width: 65%;
    }
  }

  @media (max-width: $maxWidth-breakpoint1) {
    &-video {
      video {
        margin-top: 75px;
      }
    }
  }

  @media (min-width: 1001px) {
    &-content {
      h1 {
        font-size: 30px;
        margin-top: 100px;
        margin-bottom: 50px;
      }
      p {
        font-size: 22px;
        margin-bottom: 100px;
      }
    }
  }
}
</style>
