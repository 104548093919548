<template>
  <section class="cmc">
    <div class="align-center">
      <div class="cmc-container container">
        <h1 class="cmc-heading">{{ $t("home.cmc") }}</h1>
        <p class="cmc-paragraph text-justify">
          {{ $t("home.cmcParagraph") }}
        </p>
        <p v-show="showEnglishTranslation" class="cmc-paragraph text-justify">
          {{ $t("home.cmcParagraphMiddle") }}
        </p>
        <p v-show="showEnglishTranslation" class="cmc-paragraph text-justify">
          {{ $t("home.cmcParagraphLast") }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Cmc",
  computed: {
    showEnglishTranslation() {
      return this.$i18n.locale === "en";
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../styles/navigation.scss";

.cmc {
  background-color: #f7f7f7;
  &-container {
    margin: 60px 30px;
  }
  &-heading {
    font-size: 26px;
  }
  &-paragraph {
    font-size: 18px;
    margin-top: 30px;
  }

  @media (min-width: 1001px) {
    &-container {
      margin: 100px 30px;
    }
    &-heading {
      font-size: 30px;
      margin-bottom: 50px;
    }
    &-paragraph {
      font-size: 22px;
    }
  }
}
</style>
