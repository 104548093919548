<template>
  <div id="app" :class="{ 'text-justify-ch': isJustifyChinaText }">
    <navigation />
    <main>
      <router-view />
    </main>
  </div>
</template>

<script>
import Navigation from "@/components/navigation/Navigation";
import { resolveCurrentLocale } from "@/i18n/utils";

export default {
  components: {
    Navigation,
  },
  created() {
    this.setLocalStorageIsMobile();
    this.setPageLanguage();
  },
  mounted() {
    window.addEventListener("resize", this.setLocalStorageIsMobile);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setLocalStorageIsMobile);
  },
  computed: {
    isJustifyChinaText() {
      return this.$browserDetect.isFirefox && !this.isLanguageEnglish;
    },
    isLanguageEnglish() {
      return this.$i18n.locale === "en";
    },
  },
  methods: {
    setLocalStorageIsMobile() {
      const isMobile =
        (window.innerWidth || document.documentElement.innerWidth) <= 1400;
      localStorage.setItem("isMobile", isMobile);
      if (!isMobile) {
        document.querySelector("body").classList.remove("modal-open");
      }
    },
    setPageLanguage() {
      const currentLocale = resolveCurrentLocale();
      const lang = !currentLocale ? "en" : currentLocale;
      this.$root.$i18n.locale = lang;
    },
  },
  metaInfo() {
    return {
      htmlAttrs: {
        lang: this.isLanguageEnglish ? "en-GB" : "zh-CN",
      },
      title: this.isLanguageEnglish ? "GeCell Therapeutics" : "精缮科技",
      meta: [
        {
          name: "description",
          content: this.isLanguageEnglish
            ? "In GeCell Therapeutics, we leverage the power of non-viral genetic engineering and gene delivery technologies to develop novel gene and cell therapies for ..."
            : "利用非病毒的基因工程技术和基因递送技术，精缮科技致力于为遗传疾病和肿瘤开发创新性的细胞和基因治疗方法。",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+SC&display=swap");
@import "styles/variables.scss";

.align-center {
  display: flex;
  justify-content: center;
}

.container {
  max-width: 1000px;
  width: 100%;
  margin: 0 30px;
}

.text-justify,
.text-justify-en {
  text-align: justify;
}

.text-justify-ch .text-justify {
  text-justify: inter-character;
}

* {
  margin: 0;
  padding: 0;
}

body,
::before,
::after {
  box-sizing: border-box;
  letter-spacing: 0.5px;
}

#app {
  font-family: Roboto, Noto Sans SC, Microsoft Yahei, STXihei, SimHei, Hei Ti,
    Helvetica neue, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $main-black;
}

button {
  font-family: Roboto, Noto Sans SC, Microsoft Yahei, STXihei, SimHei, Hei Ti,
    Helvetica neue, Helvetica, sans-serif;
}

body.modal-open {
  overflow-y: hidden;
}
</style>
